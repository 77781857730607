import DeleteIcon  from '@mui/icons-material/Delete';
import React, {useEffect} from "react";
import { TextField, Grid } from "@material-ui/core";
import {IconButton} from '@mui/material';
import useStyles from "./styles";

export default function UploadingMatterportListItem(props) {

    const {
        tour,
        tourIndex,
    } = props;

    const classes = useStyles();

    useEffect(() => {
    })

    const setMatterportTourName = (event) =>{
      const newName = event.target.value;
      props.setMatterportTourName(tourIndex, newName);
    }

    const setMatterportTourID = (event) =>{
      const newID = event.target.value;
      props.setMatterportTourID(tourIndex, newID);
    }

    return(
        <>
        <Grid 
          container 
          spacing={1}
          key={tourIndex}
          className={classes.matterportTourListItem_container}
          >
          
          <Grid item xs={5}>
            <TextField
                error={!tour.tourLink}
                label='Tour ID'
                size="medium"
                defaultValue={tour.tourLink}
                variant="outlined"
                className={classes.matterportTourListItem_link}
                onChange={(e) => setMatterportTourID(e)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
                error={!tour.tourName}
                label="Tour Name"
                size="medium"
                defaultValue={tour.tourName}
                variant="outlined"
                className={classes.matterportTourListItem_name}
                onChange={(e) => setMatterportTourName(e)}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton 
                classes={classes.matterportTourListItem_deleteButton}
                aria-label="delete"
                size="medium"
                variant="outlined"
                onClick={ ()=> props.deleteUploadingMatterportTour(tour)}
                >
                <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
        </>
      );

}