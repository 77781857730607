import React, { useState, useReducer } from "react";
import {
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Typography,
  Snackbar,
  Tooltip,
} from "@material-ui/core";

import "react-dropzone-uploader/dist/styles.css";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";

import { Alert } from "@material-ui/lab";

import useStyles from "./styles";

import Header from "../../../../components/Header/Header";
import ViewUser from "./ViewUser";
import ViewTour from "./ViewTour";
import AddProject from "./AddProject";
import ViewAllUsers from "./ViewAllUsers";
import ViewProject from "./ViewProject";

export default function Admin(props) {
  var classes = useStyles();
  const {promptToInstall, history, prompt} = props;
  {/* Dialog Flags*/}
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [updateProjectFilesDialogOpen, setUpdateProjectFilesDialogOpen] = useState(false);
  const [projectDialogOpen, setProjectDialogOpen] = useState(false);

  const [isAgent, setIsAgent] = useState(false);

  const [userId, setUserId] = useState("");
  const [projectId, setProjectId] = useState("");

  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");

  {/* Variable that tells which component to render*/}
  const [component, setComponent] = useState("allusers");

  const [saveLoading, setIsSaveLoading] = useState(false);

  {/* Current tour that is being rendered (Viewing actual tour)*/}
  const [tour, setTour] = useState();

  const [nonZipFiles, setNonzipFiles] = useState([]);
  const [projectDisplayName, setProjectDisplayName] = useState("");
  const [address, setAddress] = useState({});
  const [unit, setUnit] = useState("");
  {/* List of tours that will be Added upon creating new project or when adding additional files to project*/}
  const [matterportTours, setMatterportTours] = useState([])
  const [iGuideTours, setIGuideTours] = useState([])
  {/* Remove tour (IGuide or Matterport) from List */}
  const [deletingUploadedIGuide, setDeletingUploadedIGuide] = useState(false);
  const [saveAndInviteLoading, setIsSaveAndInviteLoading] = useState(false);

  {/* Snackbar flags (Notifications on bottom of the screen )*/}
  const [successMessage, setSuccessMessage] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);

  const [update, forceUpdate] = useReducer(x => x + 1, 0);
  const [currentlyUploadingZip, setUploadingZip] = useState(false);

  const handleClickOpen = () => {
    if (component === "user") {
      setProjectDialogOpen(true);
    } else if( component === "allusers") {
      setUserDialogOpen(true);
    } else if(component === "project"){
      setUpdateProjectFilesDialogOpen(true);
    }
  };

  const handleUserDialogClose = () => {
    setFirstName("");
    setLastName("");
    setAddress({});
    setUnit("");
    setEmail("");
    setCompany("");
    setIsSaveLoading(false);
    setIsSaveAndInviteLoading(false);
    setUserDialogOpen(false);
    setMatterportTours([]);
    setNonzipFiles([]);
  };

  const handleProjectDialogClose = async () => {
    // In case deleting zips from aws is still in proggress => wait for it
    if(deletingUploadedIGuide){
      return;
    }

    if(iGuideTours && iGuideTours.length > 0){
      setDeletingUploadedIGuide(true);
      let tourZipFileNames = iGuideTours.map((t) => t.tourZipFileName);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tourZipFileNames
        }),
      };

      await fetch("api/user/deleteUploadedZipsFromAWS", requestOptions)
      .then(response => response.status)
      .then(status =>{
        if(!status || status !== 200){
          console.log(`Failed to delete uploaded Tours`);
        }
      })
      .catch((error) =>{
        console.log(`Failed to delete uploaded Tours ${error}`);
      });
    }

    setAddress({});
    setProjectDisplayName("");
    setUnit("");
    setIsSaveLoading(false);
    setProjectDialogOpen(false);
    setMatterportTours([]);
    setIGuideTours([]);
    setNonzipFiles([]);
    setDeletingUploadedIGuide(false);
    setCurrentlyUploadingZip(false);

  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const selectDisplayProjectName = projectDisplayName=>{
    setProjectDisplayName(projectDisplayName);
  }
  const deleteUploadingMatterportTour = (tour)=>{
    let tours = JSON.parse(JSON.stringify(matterportTours));
    let tourIndex = tours.findIndex(t => t.tourName === tour.tourName &&t.tourLink === tour.tourLink);
    if(tourIndex > -1){
      tours.splice(tourIndex, 1);
      setMatterportTours(tours);
    }
  }

  const deleteUploadingIGuideTour = async (tour) =>{
    let tours = JSON.parse(JSON.stringify(iGuideTours));
    let tourIndex = tours.findIndex(t=> t.tourName === tour.tourName && t.tourFolderName === tour.tourFolderName);
    if(tourIndex > -1){
      const tourZipFileName = tours[tourIndex].tourZipFileName;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tourZipFileName
        }),
      };
      await fetch("/api/user/deleteUploadedZipFromAWS", requestOptions)
      .then(response => response.status)
      .then(status =>{
        if(status && status === 200){
          tours.splice(tourIndex, 1);
          setIGuideTours(tours);
        }else{
          setErrorMessage(`Something went wrong while removing zip file!`);
          setErrorOpen(true);
        }
      })
      .catch(error => {
        setErrorMessage(`Something went wrong while removing zip file!`);
        setErrorOpen(true);
      });
    }
  }

  const setMatterportTour = (tour) =>{
    let tours = [...matterportTours];

    tours.push(tour);
    setMatterportTours(tours);
  }
  const setIGuideTour = (tour)=> {
    let tours = [...iGuideTours];

    tours.push(tour);
    setIGuideTours(tours);
  }

  const selectAddress = address => {
    setAddress(address);
  };

  const handleSaveNewUser = async invite => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        company,
        name: lastName + " " + firstName,
        firstName: firstName,
        lastName: lastName,
        invite,
      }),
    };

    if (invite) {
      setIsSaveAndInviteLoading(true);
    } else {
      setIsSaveLoading(true);
    }
    await fetch("/api/user/addNewUser", requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          if (invite) {
            setIsSaveAndInviteLoading(false);
            setSuccessMessage(
              `Successfully saved and invited user: ${firstName} ${lastName}`,
            );
          } else {
            setIsSaveLoading(false);
            setSuccessMessage(
              `Successfully saved user: ${firstName} ${lastName}`,
            );
          }
          handleUserDialogClose();
          setSuccessOpen(true);
          forceUpdate();
        } else {
          setErrorMessage(`Something went wrong while saving user: ${data.error}`);
          setErrorOpen(true);
          handleUserDialogClose();
        }
      })
      .catch(err => {
        setErrorMessage(`Something went wrong while saving user`);
        setErrorOpen(true);
        handleUserDialogClose();
      });
  };

  const handleSaveNewProject = async () => {
    setIsSaveLoading(true);

    const formData  = new FormData();
    formData.append("userId", userId);
    formData.append("displayName", projectDisplayName);
    let addressToSend = (address && Object.keys(address).length > 0) ? JSON.stringify({ ...address, unit }): "";
    formData.append("address", addressToSend);
    formData.append("matterportTours", JSON.stringify(matterportTours));
    formData.append("iGuideTours", JSON.stringify(iGuideTours));

    if(nonZipFiles){
      for(let i = 0; i< nonZipFiles.length; i++){
        formData.append("files", nonZipFiles[i]);
      }
    }

    let requestOptions = {
      method: "POST",
      body: formData,
      connection: "keep-alive",
    };

    await fetch("/api/user/addNewProject", requestOptions)
    .then(response =>{
      return response.json();
    })
    .then(data =>{
      if(data.success){
        setIsSaveLoading(false);
        setSuccessMessage(`Successfully saved project`);
        setSuccessOpen(true);
        handleProjectDialogClose();
        forceUpdate();
      }else{
        setErrorMessage(`Something went wrong while saving project`);
        setErrorOpen(true);
        setIsSaveLoading(false);
      }
    })
    .catch(err =>{
      setErrorMessage(`Something went wrong while saving project`);
      setErrorOpen(true);
      setDeletingUploadedIGuide(false);
      setIsSaveLoading(false);
    });
  };

  const handleUpdateProject = async () => {
    setIsSaveLoading(true);
    const formData  = new FormData();
    formData.append("userId", userId);
    formData.append("projectId", projectId);
    formData.append("matterportTours", JSON.stringify(matterportTours));
    formData.append("iGuideTours", JSON.stringify(iGuideTours));

    if(nonZipFiles){
      for(let i = 0; i< nonZipFiles.length; i++){
        formData.append("files", nonZipFiles[i]);
      }
    }

    let requestOptions = {
      method: "POST",
      body: formData,
    };

    await fetch("/api/user/updateProjectFiles", requestOptions)
    .then(response =>{
      return response.json();
    })
    .then(data =>{
      if(data.success){
        setIsSaveLoading(false);
        setSuccessMessage(`Successfully Updated Project Files`);
        setSuccessOpen(true);
        setIGuideTours([]);
        handleCancelUpdateProject();
        forceUpdate();
      }else{
        setErrorMessage(`Something went wrong while saving project`);
        setErrorOpen(true);
      }
    })
    .catch(error =>{
      setErrorMessage(`Something went wrong while saving project`);
      setErrorOpen(true);
    });
  }
  const handleCancelUpdateProject = async () =>{
    setNonzipFiles([]);
    setMatterportTours([]);
    setCurrentlyUploadingZip(false);
    if(iGuideTours && iGuideTours.length > 0){
      iGuideTours.forEach(tour => {
        deleteUploadingIGuideTour(tour);
      });
      setIGuideTours([]);
    }
    setUpdateProjectFilesDialogOpen(false);
  }

  const handleCancelSaveProjectDialog = () =>{
    if(saveLoading){
      return;
    }

    handleProjectDialogClose();
  }
  function areIGuideToursValid(){
    const tours = [...iGuideTours];
    if(tours){

      for(let i =0; i< tours.length; i++){
        if(!tours[i].tourZipFileName || !tours[i].tourName){
          //console.log(`Found iGuide tours with empty fields`);
          return false;
        }
      }

      for(let i=0; i< tours.length; i++){
        let tempTours = [...tours];
        tempTours.splice(i,1);
        let duplicateArray = tempTours.map(tour => tour.tourName === tours[i].tourName);
        if(duplicateArray.length > 0){
          for(let j = 0; j<duplicateArray.length; j++){
            if(duplicateArray[j]){
                // Found duplicate
              //console.log(`Found duplicate iGuide Tour tour with name: ${tours[i].tourName}`);
              return false;
            }
          }
        }
      }
    }
    //console.log(`IGuide tours are all valid`);
    return true;
  }

  function areMatterportToursValid(){
    const tours = [...matterportTours];
    if(tours){

      for(let i = 0; i < tours.length; i++){
        if(!tours[i].tourName || !tours[i].tourLink){
          //console.log(`Found matterport tours with empty fields`);
          return false;
        }
      }

      for(let i=0; i< tours.length; i++){
        let tempTours = [...tours];
        tempTours.splice(i,1);
        let duplicateArray = tempTours.map(tour => tour.tourName === tours[i].tourName);
        if(duplicateArray.length > 0){
          for(let j = 0; j<duplicateArray.length; j++){
            if(duplicateArray[j]){
              //console.log(`Found duplicate Matterport Tour tour with name: ${tours[i].tourName}`);
              return false;
            }
          }
        }
      }
    }

    //console.log(`Matterport tours are all valid`);
    return true;
  }

  const setCurrentlyUploadingZip = (isUploading)=>{
    setUploadingZip(isUploading);
  }

  const disableUserDialogSubmit = () => {
    //TODO: disable if file is in the middle of uploading
    return (
      saveLoading ||
      saveAndInviteLoading ||
      !(firstName && lastName && email)
    );
  };

  const disableProjectDialogSubmit = () => {
    //TODO: disable if file is in the middle of uploading
    let disabled = true;

    disabled =
      currentlyUploadingZip ||
      saveLoading ||
      (Object.keys(address).length === 0 && !projectDisplayName) ||
      (!areMatterportToursValid() || !areIGuideToursValid() );
    return disabled;
  };

  const handleUserClick = userId => {
    setComponent("user");
    setUserId(userId);
    setProjectId(null);
  };

  const handleProjectClick = (projectId, userName, userId, isAgent) => {

    setComponent("project");
    setTour(null);
    setProjectId(projectId);
    setUserId(userId);
    setUserName(userName);
    setIsAgent(isAgent);
  };

  const handleAllUserClick = () => {
    setComponent("allusers");
    setUserId(null);
    setProjectId(null);
  };

  const handleTourClick = (tour) =>{
    setTour(tour);
    setComponent("tour");
  }

  function getTooltipDisplayStyle(){
    if(component === "tour"){
      return "none";
    }
    return "inline-flex";
  }

  const getTooltip = () => {
    if (component === "user") {
      return "Add Project";
    }
    if(component === "allusers") {
      return "Add Customer";
    }
    if(component === "project"){
      return "Add Files";
    }
  };


  const setMatterportName = (tourIndex, newName) =>{
    let tours = [...matterportTours];
    let tour = tours[tourIndex];

    if(tour){
      newName = newName.trimEnd().trimStart();
      tour.tourName = newName;
    }
    setMatterportTours(tours);
  }

  const setMatterportID = (tourIndex, newID) => {
    let tours = [...matterportTours];
    let tour = tours[tourIndex];

    if(tour){
      newID = newID.trimEnd().trimStart();
      tour.tourLink = newID;
    }
    setMatterportTours(tours);
  }

  const setIGuideTourName = (tourIndex, newName) =>{
    let tours = [...iGuideTours];
    let tour = tours[tourIndex];

    if(tour){
      tour.tourName = newName;
    }
    setIGuideTours(tours);
  }

  const setIGuideZipName = (tourIndex, newName) =>{
    let tours = [...iGuideTours];
    let tour = tours[tourIndex];

    if(tour){
      tour.tourZipFileName = newName;
    }
    setIGuideTours(tours);
  }

  return (
    <>
      <div className={classes.root}>
        <Header history={history} promptToInstall={promptToInstall} prompt={prompt}/>
        <div
          className={
            component !== "tour" ? classes.content : classes.contentTour}
          >
          {
            component !== "tour" &&(
              <div className={classes.fakeToolbar} />
            )
          }
          {component === "project" ? (
            <ViewProject
              isAgent={isAgent}
              userId={userId}
              projectId={projectId}
              userName={userName}

              isFromAdmin={true}
              saveLoading={saveLoading}
              update={update}

              updateProjectFilesDialogOpen={updateProjectFilesDialogOpen}
              setUpdateProjectFilesDialogOpen={setUpdateProjectFilesDialogOpen}

              handleAllUserClick={handleAllUserClick}
              handleUserClick={handleUserClick}

              handleCancelUpdateProject={handleCancelUpdateProject}

              matterportTours={matterportTours}
              handleMatterportTourClick={handleTourClick}
              setMatterportTour={setMatterportTour}
              setMatterportTourName={setMatterportName}
              setMatterportTourID={setMatterportID}
              deleteUploadingMatterportTour={deleteUploadingMatterportTour}

              iGuideTours={iGuideTours}
              handleIGuideTourClick={handleTourClick}
              setIGuideTour={setIGuideTour}
              setIGuideTourName={setIGuideTourName}
              setIGuideZipName={setIGuideZipName}

              handleUpdateProject={handleUpdateProject}

              deleteUploadingIGuideTour={deleteUploadingIGuideTour}

              nonZipFiles={nonZipFiles}
              setNonzipFiles={setNonzipFiles}

              areMatterportToursValid={() => areMatterportToursValid()}
              areIGuideToursValid={() => areIGuideToursValid()}
              currentlyUploadingZip={currentlyUploadingZip}
              setCurrentlyUploadingZip={setCurrentlyUploadingZip}
              forceUpdate={forceUpdate}
            />
          ) : component === "user" ? (
            <ViewUser
              userId={userId}
              handleAllUserClick={handleAllUserClick}
              handleProjectClick={handleProjectClick}
              isFromAdmin={true}
              update={update}
              forceUpdate={forceUpdate}
            />
          ): component === "tour" ?(
            <ViewTour
              userId={userId}
              projectId={projectId}
              tourId={tour?._id}
              userName={userName}
              handleAllUserClick={handleAllUserClick}
              handleUserClick={handleUserClick}
              handleProjectClick={handleProjectClick}
              update={update}
              forceUpdate={forceUpdate}
              isFromAdmin={true}
            />
          )
          : (
            <ViewAllUsers
              handleUserClick={handleUserClick}
              update={update}
              forceUpdate={forceUpdate}
            />
          )}
          <Snackbar
            open={successOpen}
            autoHideDuration={6000}
            onClose={handleSuccessClose}
          >
            <Alert onClose={handleSuccessClose} severity="success">
              {successMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={errorOpen}
            autoHideDuration={6000}
            onClose={handleErrorClose}
          >
            <Alert onClose={handleErrorClose} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>

          <Tooltip title={getTooltip()}>
            <Fab
              color="secondary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{
                display: getTooltipDisplayStyle()
              }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>

          <Dialog
            id="add-project-dialog"
            open={projectDialogOpen}
            aria-labelledby="form-dialog-title"
            classes={{
              paper: classes.paper,
            }}
          >
            <DialogContent>
              <DialogTitle>Add Project</DialogTitle>
              <AddProject
                matterportTours={matterportTours}
                setMatterportTour={setMatterportTour}
                setMatterportTourName={setMatterportName}
                setMatterportTourID={setMatterportID}
                deleteUploadingMatterportTour={deleteUploadingMatterportTour}

                iGuideTours={iGuideTours}
                setIGuideTour={setIGuideTour}
                setIGuideTourName={setIGuideTourName}
                setIGuideZipName={setIGuideZipName}
                deleteUploadingIGuideTour={deleteUploadingIGuideTour}

                setNonzipFiles={setNonzipFiles}

                setDisplayProjectName={selectDisplayProjectName}
                setUnit={setUnit}
                selectAddress={selectAddress}
                setCurrentlyUploadingZip={setCurrentlyUploadingZip}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCancelSaveProjectDialog}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveNewProject}
                color="primary"
                id="save-and-invite"
                disabled={disableProjectDialogSubmit()}
                variant="contained"
                startIcon={<SaveIcon />}
              >
                {saveLoading ? (
                  <CircularProgress size={14} />
                ) : (
                  <Typography>Save</Typography>
                )}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            id="add-user-dialog"
            open={userDialogOpen}
            onClose={handleUserDialogClose}
            aria-labelledby="form-dialog-title"
            classes={{
              paper: classes.add_customer_container,
            }}
          >
            <DialogTitle id="form-dialog-title">Add Customer</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                label="First Name"
                InputProps={{ name: "firstName" }}
                onChange={e => setFirstName(e.target.value)}
                value={firstName}
                className={classes.names}
                required
                variant="outlined"
              />
              <TextField
                label="Last Name"
                InputProps={{ name: "lastName" }}
                onChange={e => setLastName(e.target.value)}
                value={lastName}
                className={classes.names}
                required
                variant="outlined"
              />
              <TextField
                label="Company Name"
                InputProps={{ name: "company" }}
                onChange={e => setCompany(e.target.value)}
                value={company}
                className={classes.names}
                variant="outlined"
              />
              <TextField
                label="Email Address"
                type="email"
                InputProps={{ name: "email" }}
                onChange={e => setEmail(e.target.value)}
                value={email}
                className={classes.names}
                required
                variant="outlined"
              />
            </DialogContent>
            <DialogActions
              className={classes.btn_wrapper}
            >
              <Button onClick={handleUserDialogClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => handleSaveNewUser(false)}
                color="secondary"
                id="save"
                disabled={disableUserDialogSubmit()}
                variant="contained"
                startIcon={<SaveIcon />}
              >
                {saveLoading ? (
                  <CircularProgress size={14} />
                ) : (
                  <Typography>Save</Typography>
                )}
              </Button>
              <Button
                onClick={() => handleSaveNewUser(true)}
                color="primary"
                id="save-and-invite"
                disabled={disableUserDialogSubmit()}
                variant="contained"
                startIcon={<PersonAddIcon />}
              >
                {saveAndInviteLoading ? (
                  <CircularProgress size={14} />
                ) : (
                  <Typography>Save And Invite</Typography>
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
}
