import React, {useState} from "react";
import { Button, Grid} from "@material-ui/core";

import useStyles from "./styles"
export default function NonZipFileUploader(props){

    const classes = useStyles();
    const [files, setFiles] = useState([]);

    const changeFunc = () =>{
        const inputElement = document.getElementById('file');
        let files = [];
        if(inputElement.files && inputElement.files.length > 0){
            files = Object.values(inputElement.files);
            setFiles(files);
        }
        props.setNonzipFiles(files);
    }

    const renderFile = (value, index) =>{
        return(
            <div key={index}> 
                {value.name}
            </div>
        );
    }

    return(
        <>
            <Grid 
                container
                spacing={1}
            >
                <Grid item xs={6}>
                    <form 
                        encType="multipart/form-data"
                    >
                        <div 
                            className={classes.fileUploader_input_contianer}
                            >
                            <input
                                accept="*"
                                className={classes.fileUploader_input}
                                id="file"
                                onChange={changeFunc}
                                multiple
                                type="file"
                            />
                            <label htmlFor="file">
                                <Button 
                                    size="large"
                                    color="primary"
                                    variant="contained" 
                                    component="span"
                                    >
                                    Upload
                                </Button>
                            </label>
                        </div>
                    </form>
                </Grid>
                <Grid item xs={6}>
                    {files.length > 0 && (
                        < div className={classes.filesUploaded_container}>
                        {
                            files.map( (value, index) => renderFile(value,index))
                        }
                        </div>
                    )}
                </Grid>
            </Grid>


        </>
    );

}