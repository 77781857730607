import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumbs,
  Link,
  Typography,
  ListItem,
  Checkbox,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  TextareaAutosize,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import * as fileSaver from "file-saver";
import useStyles from "./styles";
import { Alert } from "@material-ui/lab";
import {generateProjectName, getEmbeddedLinkForTour} from "./functions";
import { togglePublicStatus } from "./functions";

import UpdateProject from "./AddTours/UpdateProject";
import ProjectFileListing from "./InspectingProject/ProjectFileListing";
import ProjectIGuideToursListing from "./InspectingProject/ProjectIGuideToursListing";
import ProjectMatterportToursListing from "./InspectingProject/ProjectMatterportToursListing";

export default function ViewProject(props){
    const [project, setProject] = useState();
    const [checked, setChecked] = useState([]);
    const [allFilesChecked, setAllFilesChecked] = useState(false);

    const [copied, setCopied] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [successOpen, setSuccessOpen] = useState(false);

    const [updateTourNameDialog, setUpdateTourNameDialog] = useState(false);
    const [updateTour, setUpdateTour] = useState({});
    const [updateTourName, setUpdateTourName] = useState("");
    const [downloadingFiles, setIsDownloadFiles] = useState(false);
    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
    const [textAreaUrl, setTextAreaUrl] = useState("");

    const tourLinkRef = useRef(null);

    const classes = useStyles();

    const {
        userId,
        projectId,
        userName,
        handleAllUserClick,
        handleUserClick,
        handleMatterportTourClick,
        handleIGuideTourClick,
        update,
        isFromAdmin,
        isAgent,
    } = props;

    useEffect(() =>
    {
        const fetchData = async () => {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ projectId, userId }),
          };

          const result = await fetch(
            "/api/project/get",
            requestOptions,
          ).then(response => response.json());
          if (result && result.project) {
            setProject(result.project);
          }
        };
        fetchData();
        return () => {
            setProject(null);
        }
    }, [projectId, userId, update]);

    const viewAllUsers = event => {
        event.preventDefault();
        handleAllUserClick();
    };

    const generateBreadcrumbs = () => {
        if (isFromAdmin) {
          return (
            <div className={classes.bread_crumbs_wrapper}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link href="#" color="inherit" onClick={e => viewAllUsers(e)}>
                  All users
                </Link>
                <Link
                  href="#"
                  color="inherit"
                  onClick={e => {
                    e.preventDefault();
                    handleUserClick(userId);
                  }}
                >
                  {userName}
                </Link>
                <Typography color="textPrimary" aria-current="page" >
                  {project && generateProjectName(project)}
                </Typography>
              </Breadcrumbs>
                {renderAllProjectsButton()}
            </div>
          );
        } else {
          return (
            <div className={classes.bread_crumbs_wrapper}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link href="#" color="inherit" onClick={e => handleUserClick(e)}>
                  All projects
                </Link>
                <Typography color="textPrimary" aria-current="page">
                  {project && generateProjectName(project)}
                </Typography>
              </Breadcrumbs>
              {renderAllProjectsButton()}
              </div>
          );
        }
    };

    const renderAllProjectsButton = () => {
      return (
        <Button
          onClick={() => handleUserClick(userId)}
          variant="contained"
          >
          <Typography aria-current="page">ALL PROJECTS</Typography>
        </Button>
      );
    }
    const generateHeader = () =>{
      return(
        <>
          <ListItem
            container
            spacing={1}
			      className={classes.header_wrapper}
            >
			      <div style={{display:"flex"}}>
              <Checkbox
			  	      style={{marginRight: "30px"}}
                edge="start"
                checked={allFilesChecked}
                onClick={handleAllFilesCheckbox}
                tabIndex={-1}
                disableRipple
              />
              <h1>Files:</h1>
			      </div>
              <Button
                disabled={checked.length === 0}
                onClick={() => {downloadFiles()}}
                variant="contained"
                style={{marginLeft: "30px"}}
                >
                { downloadingFiles ? (
                  <CircularProgress size={14}/>
                  ) : (
                  <Typography>  Download files</Typography>
                )}

              </Button>
          </ListItem>
        </>
      );
    }

    const disableUpdateProjectDialogSubmit = ()=>{
      let disabled = true;
      disabled =
        props.saveLoading ||
        props.currentlyUploadingZip ||
        (!props.areMatterportToursValid() || !props.areIGuideToursValid() );

      return disabled;
    }

    const handleProjectFilesClose = () =>{
      if(props.saveLoading){
        return;
      }
      props.handleCancelUpdateProject();
    }

    const handleTogglePublicStatus = (checked, tour) => {
        togglePublicStatus(
          checked,
          project,
          tour,
          setSuccessMessage,
          setSuccessOpen,
          setErrorMessage,
          setErrorOpen,
          props.forceUpdate,
        );
    }
    const downloadFiles = async () => {
      setIsDownloadFiles(true);
      let listOfFiles = project.uploadedFiles.filter((file, index)=> {
        return checked.includes(index)
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          listOfFiles
        }),
      };

      fetch("/api/project/downloadFiles", requestOptions)
          .then(async (response) => {
            const blob = await response.blob();

            // Download the file
            fileSaver.saveAs(blob, "files.zip");
            setChecked([]);
            setAllFilesChecked(false);
            setIsDownloadFiles(false);
          })
          .catch(error => {
            console.log('error while downloading:', error);
            setIsDownloadFiles(false);
          });
    }
    const handleFileCheckedToggle = (index) => () => {
      const currentIndex = checked.indexOf(index);
      const newChecked = [...checked];

      if (currentIndex === -1) {
          newChecked.push(index);
      } else {
          newChecked.splice(currentIndex, 1);
      }

      if(newChecked.length === 0 && allFilesChecked){
          setAllFilesChecked(false);
      }

      setChecked(newChecked);
    };
    const handleAllFilesCheckbox = () =>{

      let newAllFilesChecked = !allFilesChecked;
      let newChecked = [...checked];

      // Select all
      if(newAllFilesChecked){
        for(let i = 0; i< project.uploadedFiles.length;i++){
          let currentIndex = newChecked.indexOf(i);
          if(currentIndex === -1){
            newChecked.push(i)
          }
        }
        // Deselect all
      }else{
        newChecked = [];
      }

      setAllFilesChecked(newAllFilesChecked);
      setChecked(newChecked);
    }

    const handleUpdateTourNameDialogClose = () =>{
      setUpdateTourNameDialog(false);
      setUpdateTour({});
      setUpdateTourName("");
    }
    const handleUpdateMatterportTourName = (tour) =>{
      setUpdateTourNameDialog(true);
      setUpdateTour(tour);
      setUpdateTourName(tour?.tourName || '');
    }
    const handleUpdateIGuideTourName = (tour) =>{
      setUpdateTourNameDialog(true);
      setUpdateTour(tour);
      setUpdateTourName(tour?.tourName || '');
    }
    const handleUpdateProject = () =>{
      props.handleUpdateProject();
    }
    const handleUpdateTourName = async () =>{
      if(!updateTourName || !updateTour){
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          projectId: project.id,
          tourId: updateTour._id,
          tourName:updateTourName,
          tourType: updateTour.tourLink? "matterport": "iguide",
        }),
      };

      const response = await fetch("/api/project/updateTourName", requestOptions)
      .then((response) =>{
        return response.json();
      })
      .catch(err =>{
        console.log('err: ', err);
        setErrorOpen(true);
      });

      if(response && response.success){
        handleUpdateTourNameDialogClose();
        props.forceUpdate();
      }else{
        setErrorOpen(true);
      }
    }

    const handleOpenCopyLinkModal = (tour) => {
      if(!tour?.isPublic){
        setErrorOpen(true);
        setErrorMessage("Tour is not public cannot copy.")
        return;
      }

      const copyTourLink = getEmbeddedLinkForTour(tour);
      setTextAreaUrl(copyTourLink);
      setIsCopyModalOpen(true);
    };

    const setMatterportTour = (tour) =>{
      props.setMatterportTour(tour);
    }
    const setIGuideTour = (tour)=>{
      props.setIGuideTour(tour);
    }
    const setNonzipfiles = (files) =>{
      props.setNonzipFiles(files);
    }

    const handleDeleteUploadingMatterportTour = (tour)=>{
      props.deleteUploadingMatterportTour(tour);
    }
    const handleDeleteUploadingIGuideTour = (tour) =>{
      props.deleteUploadingIGuideTour(tour);
    }

    const handleDeleteProjectFile = (fileToDelete) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          projectId: project.id,
          fileName: fileToDelete,
        })
      };

      fetch("/api/project/deleteFile", requestOptions)
        .then((response) =>{
          setProject({
            ...project,
            uploadedFiles: project.uploadedFiles.filter(file => file !== fileToDelete)
          });
        })
        .catch(err =>{
          console.log('err: ', err);
          setErrorOpen(true);
        });
    }

    const handleDeleteProjectTour = (tourType, tourName) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          projectId: project.id,
          tourName: tourName,
        })
      };

      fetch("/api/project/deleteTour", requestOptions)
        .then((response) =>{
          setProject({
            ...project,
            [tourType]: project[tourType].filter(tour => tour.tourName !== tourName)
          });
        })
        .catch(err =>{
          console.log('err: ', err);
          setErrorOpen(true);
        });
    }

    const handleErrorClose = ()=>{
      setErrorOpen(false);
      setErrorMessage("");
    }
    const handleSuccessClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSuccessOpen(false);
    };

    const setMatterportTourName = (tourIndex, newName) =>{
      props.setMatterportTourName(tourIndex, newName);
    }
    const setMatterportTourID = (tourIndex, newID) =>{
      props.setMatterportTourID(tourIndex, newID);
    }

    const setIGuideZipName = (tourIndex, newName) =>{
      props.setIGuideZipName(tourIndex, newName);
    }
    const setIGuideTourName = (tourIndex, newName) =>{
      props.setIGuideTourName(tourIndex, newName);
    }

    const setCurrentlyUploadingZip = (isUploading)=>{
      props.setCurrentlyUploadingZip(isUploading);
    }

    const copyLink = () => {
      navigator.clipboard.writeText(textAreaUrl).then(function() {
        /* clipboard successfully set */
        setCopied(true);
        setIsCopyModalOpen(false);
        setTextAreaUrl("");
      }, function() {
        /* clipboard write failed */
        setErrorOpen(true);
        setErrorMessage("Failed to copy link");
      });
    }

    const renderCopyLinkModal = () => {
      return (
        <Dialog
          id="copy-link-dialog"
          open={isCopyModalOpen}
          onClose={() => setIsCopyModalOpen(false)}
          aria-labelledby="form-dialog-title"
          width="xs"
          classes={{
            paper: classes.paper,
          }}
        >
          <DialogTitle>Tour Link</DialogTitle>
          <DialogContent>
            <TextareaAutosize
              value={textAreaUrl}
              className={classes.textareaLinks}
            />
          </DialogContent>
          {document.queryCommandSupported("copy") && (
            <DialogActions>
              <Button color="secondary" variant="contained" onClick={copyLink}>
                Copy to clipboard
              </Button>
            </DialogActions>
          )}
        </Dialog>
      );
    }

    return(
        <>
            {generateBreadcrumbs()}
            {renderCopyLinkModal()}
            {
                project &&
                (
                    <>
                      {generateHeader()}
                      {
                        <ProjectFileListing
                          uploadedFiles={project.uploadedFiles}
                          checked={checked}
                          handleFileCheckedToggle={handleFileCheckedToggle}
                          handleDeleteFile={handleDeleteProjectFile}
                        />
                      }
                      {
                        <ProjectMatterportToursListing
                          project={project}
                          matterportTours={project.matterportTours}
                          handleTourClick={handleMatterportTourClick}
                          handleUpdateTourName={handleUpdateMatterportTourName}
                          handleTogglePublicStatus={handleTogglePublicStatus}
                          isFromAdmin={isFromAdmin}
                          isNotOwner={project.isNotOwner}
                          copyLink={handleOpenCopyLinkModal}
                          onDeleteTour={(tourName) => handleDeleteProjectTour('matterportTours', tourName)}
                        />
                      }
                      {
                        <ProjectIGuideToursListing
                          project={project}
                          iGuideTours={project.iGuideTours}
                          handleTourClick={handleIGuideTourClick}
                          handleUpdateTourName={handleUpdateIGuideTourName}
                          handleTogglePublicStatus={handleTogglePublicStatus}
                          isFromAdmin={isFromAdmin}
                          isNotOwner={project.isNotOwner}
                          copyLink={handleOpenCopyLinkModal}
                          onDeleteTour={(tourName) => handleDeleteProjectTour('iGuideTours', tourName)}
                        />
                      }
                      {
                        isFromAdmin && (
                          <Dialog
                            classes={{
                              paper: classes.paper,
                            }}
                            open={props.updateProjectFilesDialogOpen}
                          >
                            <DialogContent>
                              <DialogTitle> Update Project </DialogTitle>
                              <UpdateProject
                                matterportTours={props.matterportTours}
                                setMatterportTour={setMatterportTour}
                                setMatterportTourName={setMatterportTourName}
                                setMatterportTourID={setMatterportTourID}
                                deleteUploadingMatterportTour={handleDeleteUploadingMatterportTour}

                                iGuideTour={props.iGuideTour}
                                iGuideTours={props.iGuideTours}
                                setIGuideTour={setIGuideTour}
                                setIGuideTourName={setIGuideTourName}
                                setIGuideZipName={setIGuideZipName}
                                deleteUploadingIGuideTour={handleDeleteUploadingIGuideTour}

                                setCurrentlyUploadingZip={setCurrentlyUploadingZip}

                                setNonzipFiles={setNonzipfiles}
                              />
                            </DialogContent>

                            <DialogActions>
                              <Button
                                onClick={handleProjectFilesClose}
                                color="primary"
                              >
                                Cancel
                              </Button>

                              <Button
                                color="primary"
                                id="update-project"
                                variant="contained"
                                onClick={handleUpdateProject}
                                disabled={disableUpdateProjectDialogSubmit()}
                                startIcon={<SaveIcon />}
                              >
                                {props.saveLoading ? (
                                  <CircularProgress size={14} />
                                ) : (
                                  <Typography>Save</Typography>
                                )}
                              </Button>
                              </DialogActions>
                          </Dialog>
                        )
                      }
                      <Snackbar
                        onClose={()=> setCopied(false)}
                        autoHideDuration={1000}
                        open={copied}
                        message="Copied"
                      />

                      <Snackbar
                        open={successOpen}
                        autoHideDuration={6000}
                        onClose={handleSuccessClose}
                      >
                        <Alert onClose={handleSuccessClose} severity="success">
                          {successMessage}
                        </Alert>
                      </Snackbar>

                      <Snackbar
                        open={errorOpen}
                        autoHideDuration={2000}
                        onClose={(handleErrorClose)}
                      >
                        <Alert
                          onClose={handleErrorClose}
                          severity="error"
                        >
                          {errorMessage}
                        </Alert>
                      </Snackbar>

                      <Dialog
                        open={updateTourNameDialog}
                        onClose={()=> handleUpdateTourNameDialogClose()}
                        aria-labelledby="change-projectName-dialog"
                      >
                        <DialogTitle id="update-projectName-dialog">Change Tour Name</DialogTitle>
                        <DialogContent>
                            <TextField
                              label="New Tour Name"
                              onChange={(e)=> setUpdateTourName(e.target.value)}
                              value={updateTourName}
                              variant="outlined"
                            >
                            </TextField>
                        </DialogContent>
                        <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => handleUpdateTourNameDialogClose()}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleUpdateTourName();
                            }}
                            color="primary"
                          >
                            confirm
                          </Button>
                        </DialogActions>
                      </Dialog>

                    </>
                )
            }

        </>
    );

}