import React, {useState, useContext} from "react";

import { getFormatedDate, getBoldedTourNameStyle } from "../functions";
import { UserContext } from "../../user/UserProvider";

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";

import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
    FormControlLabel,
    Switch,
    Menu,
    MenuItem,
    IconButton,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import useStyles from './styles';

export default function ProjectIGuideToursListing({
    iGuideTours,
    isFromAdmin,
    isNotOwner,
    handleUpdateTourName,
    handleTourClick,
    copyLink,
    handleTogglePublicStatus,
    project,
    onDeleteTour
}) {

    const classes = useStyles();
    const tourIcon = '/zipTourIcon.png';

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

    const { user } = useContext(UserContext);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleMenuClick = (index, event) => {
      setMenuAnchorEl({ [index]: event.currentTarget });
    };
    const handleMenuClose = () => {
      setMenuAnchorEl(null);
    };

    const getSecondaryActions = (tour, index) =>{
        if (mobile) {
            const menuItems = [];

            if( isFromAdmin && !project.isNotOwner ){
              menuItems.push(
                <MenuItem
                  key="updateTourName"
                  onClick={() => {
                    handleUpdateTourName(tour)
                  }}
                >
                  <Button startIcon={<DriveFileRenameOutlineIcon />} disableElevation>
                    Update Tour Name
                  </Button>
                </MenuItem>,
              );
            }

            if( (!isFromAdmin && !project.isNotOwner) || (isFromAdmin && !project.isNotOwner )  ){

              menuItems.push(
                <MenuItem
                  key="makePublic"
                  onClick={() => {
                    handleTogglePublicStatus(!tour.isPublic, tour);
                  }}
                >
                  <Button
                    startIcon={
                      tour.isPublic ? <ToggleOffIcon /> : <ToggleOnIcon />
                    }
                    disableElevation
                  >
                    { !tour.isPublic ? "Enable" : "Disable"}
                  </Button>
                </MenuItem>,
              );

              menuItems.push(
                <MenuItem
                  key="embedLink"
                  onClick={() => {
                    copyLink(tour)
                  }}
                >
                  <Button startIcon={<ContentCopyIcon />} disableElevation>
                    Copy Link
                  </Button>
                </MenuItem>,
              );

            }

            if (isFromAdmin) {

              menuItems.push(
                <MenuItem
                  key="deleteTour"
                  onClick={() => onDeleteTour(tour.tourName)}
                >
                  <Button startIcon={<DeleteIcon />}
                    disableElevation>
                    Delete
                  </Button>
                </MenuItem>
              )
            }

            if ( !project.disabled && !project.isNotOwner ) {
              return (
                <>
                  <IconButton onClick={e => handleMenuClick(index, e)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={menuAnchorEl && menuAnchorEl[index]}
                    open={Boolean(menuAnchorEl && menuAnchorEl[index])}
                    onClose={handleMenuClose}
                    getContentAnchorEl={null}
                  >
                    {menuItems}
                  </Menu>
                </>
              );
            }

        }else{
            return(
              <>
                { ( isFromAdmin && !project.isNotOwner) && (
                      <div className={classes.projectiGuideTourListingActions}>
                        <Button
                          onClick={()=>handleUpdateTourName(tour)}
                        >
                          <DriveFileRenameOutlineIcon/>
                        </Button>

                        <FormControlLabel
                          control={
                            <Switch
                              checked={tour.isPublic}
                              disabled={ !(isFromAdmin || !isNotOwner) }
                              onChange={e => { handleTogglePublicStatus(e.target.checked, tour);}}
                            />
                          }
                          label="Public"
                        />

                        <Button
                          onClick={()=>copyLink(tour)}
                        >
                          <ContentCopyIcon/>
                        </Button>
                      </div>
                    )
                }
                { (!isFromAdmin && !project.isNotOwner)  && (
                      <div className={classes.projectiGuideTourListingActions}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={tour.isPublic}
                              disabled={ !(isFromAdmin || !isNotOwner) }
                              onChange={e => { handleTogglePublicStatus(e.target.checked, tour);}}
                            />
                          }
                          label="Public"
                        />
                        <Button
                          onClick={()=>copyLink(tour)}
                        >
                          <ContentCopyIcon/>
                        </Button>
                      </div>
                    )
                  }
                  { (isFromAdmin && !project.isNotOwner) &&
                    <Button onClick={() => onDeleteTour(tour.tourName)}>
                        <DeleteIcon/>
                    </Button>
                  }
              </>
            );
        }
    }

    return(iGuideTours ?
      (
        <>
        {(
        <List>
            {
            iGuideTours.map((tour, index) =>{
                return(
                <div
                  key={`file-${index}`}
                  fullWidth
                >
                    <ListItem button dense key={index}>
                    <ListItemIcon>
                        <img className={classes.projectiGuideTourListing} src={tourIcon} alt="tourIcon.png"/>
                    </ListItemIcon>
                    <ListItemText
                        id={index}
                        onClick={()=>handleTourClick(tour)}
                    >
                        <div
                          style={getBoldedTourNameStyle(tour, mobile)}
                        >
                          {tour.tourName}
                        </div>
                        <div>Added: {getFormatedDate(tour.uploaded)}</div>
                    </ListItemText>

                    {getSecondaryActions(tour, index)}

                    </ListItem>
                    <hr/>
                </div>
                );
            })
            }
        </List>
        )}
        </>
      )
      :
      (<></>)
    );
}