import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState, useReducer } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Button,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import useStyles from "./styles";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function ManageUsers(props) {
  const [users, setUsers] = useState([]);

  const [focusEmail, setFocusEmail] = useState(false);
  const [focusUserName, setFocusUserName] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { projectId, usersDialogOpen, setUsersDialogOpen } = props;
  const [update, forceUpdate] = useReducer(x => x + 1, 0);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const classes = useStyles();
  const materialTableRef = React.createRef();

  const handleAddNewUser = async newData => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (newData.name && newData.email && emailRegex.test(newData.email)) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId,
          email: newData.email,
          name: newData.name,
        }),
      };

      await fetch("/api/project/addNewUser", requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            const result = data.result;
            const user = data.user;
            let message = "";
            if (result === "NEW_USER") {
              message = `Successfully saved and invited user: ${user.name}`;
            } else if (result === "LINKED_TO_THIS_PROJECT") {
              message = `${user.name} already has an account, we added this project to his account`;
            } else if (result === "ALREADY_LINKED_TO_THIS_PROJECT") {
              message = `${user.name} is already added to this project`;
            }
            forceUpdate();
            setSuccessMessage(message);
            setSuccessOpen(true);
            setUserName("");
            setUserEmail("");
            setFocusUserName(false);
            setFocusEmail(false);
          } else {
            setErrorMessage(`Something want wrong while adding user`);
            setErrorOpen(true);
          }
        })
        .catch(err => {
          setErrorMessage(`Something want wrong while adding user`);
          setErrorOpen(true);
        });
    } else {
      //TODO: Add error message
    }
  };

  const handleDeleteUser = async rowData => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: rowData.id,
      }),
    };

    await fetch("/api/project/deleteUser", requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          forceUpdate();
          setSuccessMessage(`Successfully deleted user: ${rowData.name}`);
          setSuccessOpen(true);
        } else {
          setErrorMessage(
            `Something want wrong while deleting user: ${rowData.name}`,
          );
          setErrorOpen(true);
        }
      })
      .catch(err => {
        setErrorMessage(
          `Something want wrong while deleting user: ${rowData.name}`,
        );
        setErrorOpen(true);
      });
  };

  const handleRemoveUser = async rowData => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectId,
        userId: rowData.id,
      }),
    };

    await fetch("/api/project/removeUserFromProject", requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          forceUpdate();
          setSuccessMessage(`Successfully removed user: ${rowData.name}`);
          setSuccessOpen(true);
          forceUpdate();
        } else {
          setErrorMessage(
            `Something want wrong while removing user: ${rowData.name}`,
          );
          setErrorOpen(true);
        }
      })
      .catch(err => {
        setErrorMessage(
          `Something want wrong while removing user: ${rowData.name}`,
        );
        setErrorOpen(true);
      });
  };

  const handleUsersDialogClose = () => {
    setUsersDialogOpen(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  useEffect(() => {
    const getAllUsers = async () => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId,
        }),
      };

      await fetch("/api/project/getUsersForProject", requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            const users = data.users;
            setUsers(users);
          } else {
            setErrorMessage(`Something want wrong while loading users`);
            setErrorOpen(true);
            setUsers([]);
          }
        })
        .catch(err => {
          setErrorMessage(`Something want wrong while loading users`);
          setErrorOpen(true);
          setUsers([]);
        });
    };
    getAllUsers();
    return () => {
      setUsers([]);
    };
  }, [projectId, update]);

  const handleNameChange = (event) => {
    setUserName(event.target.value)
  }

  const handleEmailChange = (event) => {
    setUserEmail(event.target.value)
  }

  const handleFocusEmail = () => {
    setFocusUserName(false);
    setFocusEmail(true);
  }

  const handleFocusUserName = () => {
    setFocusUserName(true);
    setFocusEmail(false);
  }

  const addMore = () => {
    const newRow = {name: userName, email: userEmail};

    handleAddNewUser(newRow);
  }

  const addUserToTheProject = () => {
    return (
      <div className={classes.add_user_wrapper} >
        <div className={classes.input_name_wrapper}>
        <TextField
            autoFocus={focusUserName}
            required
            placeholder="Add Name"
            value={userName}
            onChange={handleNameChange}
            onFocus={() => handleFocusUserName()}
            variant="standard"
            />
          <div className={classes.input_email_wrapper}>
            <TextField
              required
              onFocus={() => handleFocusEmail()}
              autoFocus={focusEmail}
              placeholder="Add Email"
              value={userEmail}
              onChange={handleEmailChange}
              type="email"
              variant="standard"
          />
          </div>
        </div>
        <div className={classes.add_user_button_wrapper}>
          <Button
            color="primary"
            variant="contained"
            onClick={addMore}
            disabled = {userName.length === 0 || userEmail.length === 0}
          >
            Add User
          </Button>
        </div>
    </div>
    )
  }
    return (
    <>
      <Dialog
        id="manage-users-dialog"
        open={usersDialogOpen}
        onClose={handleUsersDialogClose}
        aria-labelledby="form-dialog-title"
        fullWidth={false}
        maxWidth={"xl"}
        classes={{ paper : classes.dialogPaper}}
      >
        <div className={classes.manageUsersHeaderWrapper}>
          <DialogTitle id="form-dialog-title">
            Manage users for this project
          </DialogTitle>
          <DialogActions>
            <div className={classes.closeIcon}>
              <Clear onClick={handleUsersDialogClose}/>
            </div>
          </DialogActions>
        </div>

        <DialogContent>
          <MaterialTable
             style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: '10px',
             }}
            icons={tableIcons}
            columns={[
              { title: "Name", field: "name" },
              { title: "Email", field: "email" },
              { field: "id", hidden: true },
            ]}
            data={users}
            tableRef={materialTableRef}
            actions={[

              rowData => ({
                icon: rowData.isManagedByCurrentUser
                  ? () => <DeleteOutline />
                  : () => <DeleteIcon />,
                tooltip: rowData.isManagedByCurrentUser
                  ? "Delete User"
                  : "Remove from this project",
                onClick: (e, rowData) =>
                  rowData.isManagedByCurrentUser
                    ? handleDeleteUser(rowData)
                    : handleRemoveUser(rowData),
                disabled: false,
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
              search: false,
              showTitle: false,
              paging: false
            }}
            components={{
              Toolbar: props => (
                addUserToTheProject()
              )
            }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
