import React from "react";
import {Grid} from "@material-ui/core";
import NonZipFileUploader from "./NonZipFileUploader";
import useStyles from "./styles";

export default function UploadingFilesComponent(
{
    setNonzipFiles,
    disabled
}
){
    const classes = useStyles();
    return(
        <div
          className={classes.updateProjectSectionBox}
        > 
          <Grid 
            container
            spacing={1}
            >
            <Grid item xs={6}>
              <h4 style={{paddingLeft:"8px"}}> Other Files </h4>
            </Grid>
            <Grid item xs={6}>
              <h4> Uploaded Files: </h4>
            </Grid>
          </Grid>
          <NonZipFileUploader  
            setNonzipFiles={setNonzipFiles}
            disabled={disabled}
          />
        </div>
    );
}