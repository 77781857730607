import React, { useState, useEffect } from "react";

import {
  TextField,
  Button,
  Snackbar,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import BusinessIcon from "@material-ui/icons/Business";
import useStyles from "./styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Alert } from "@material-ui/lab";
import { withRouter } from "react-router-dom";
import PublicView from "./PublicView";

function Register(props) {
  const [name, setName] = useState("");
  const [validatedHash, setValidatedHash] = useState(false);
  const [inviteHash, setInviteHash] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [invalidEmailError, setInvalidEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notMatchingPasswordMessage, setNotMatchingPasswordMessage] = useState(
    "",
  );
  const [passwordTooShortMessage, setPasswordTooShortMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    //Check if hash is valid, populate fields
    const getInviteHashData = async () => {
      const path = props.location.pathname;
      const hash = path.split("/").pop();
      setInviteHash(hash);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          hash,
        }),
      };
      await fetch("/api/user/checkInviteHash", requestOptions).then(response =>
        response
          .json()
          .then(data => ({
            data: data,
            status: response.status,
          }))
          .then(response => {
            if (
              response.status === 200 &&
              response.data.success &&
              response.data.user
            ) {
              setValidatedHash(true);
              setEmail(response.data.user.email);
              setName(response.data.user.name);
              setCompany(response.data.user.company);
            } else {
              props.history.push("/login");
            }
          })
          .catch(err => {
            props.history.push("/login");
          }),
      );
    };
    getInviteHashData();
  }, [props.history, props.location.pathname]);

  const handleSaveUser = async () => {
    if (name && email && password && confirmPassword && inviteHash) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          inviteHash,
          name,
          email,
          company,
          password,
          confirmPassword,
        }),
      };
      await fetch("/api/user/registerUser", requestOptions).then(response =>
        response
          .json()
          .then(data => ({
            data: data,
            status: response.status,
          }))
          .then(response => {
            if (response.data.success) {
              setSuccessMessage(`Successfully saved user: ${name}`);
              setSuccessOpen(true);
              setRedirectToLogin(response.data.redirectToLogin);
            } else if (response.data.error) {
              setErrorMessage(response.data.error);
              setErrorOpen(true);
              setRedirectToLogin(response.data.redirectToLogin);
            }
          })
          .catch(err => {
            setErrorMessage(`Something want wrong while saving user`);
            setErrorOpen(true);
          }),
      );
    }
  };

  const disableSubmit = () => {
    return !!(
      !(email && name && password && confirmPassword) ||
      invalidEmailError ||
      notMatchingPasswordMessage ||
      passwordTooShortMessage
    );
  };

  const handleEmailChange = event => {
    const emailRegex = /^(([^<>()[]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(String(event.target.value).toLowerCase())) {
      setInvalidEmailError("Invalid email");
    } else {
      setInvalidEmailError("");
    }
    setEmail(event.target.value);
  };

  const handlePasswordChange = event => {
    if (event.target.value.length < 8) {
      setPasswordTooShortMessage("Password is too short");
    } else {
      setPasswordTooShortMessage("");
    }
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = event => {
    if (event.target.value !== password) {
      setNotMatchingPasswordMessage("Passwords do not match");
    } else {
      setNotMatchingPasswordMessage("");
    }
    setConfirmPassword(event.target.value);
  };
  const handleNameChange = event => {
    setName(event.target.value);
  };
  const handleCompanyChange = event => {
    setCompany(event.target.value);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
    if (redirectToLogin) {
      props.history.push("/login");
    }
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
    if (redirectToLogin) {
      props.history.push("/login");
    }
  };

  return (
    validatedHash && (
      <PublicView>
        <Typography variant="h4" className={classes.subGreeting}>
          Create your account
        </Typography>
        <TextField
          required
          className={classes.inputField}
          label="Name"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField,
            },
          }}
          value={name}
          error={Boolean(!name)}
          onChange={handleNameChange}
          fullWidth
        />

        <TextField
          required
          className={classes.inputField}
          label="Email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField,
            },
          }}
          onChange={handleEmailChange}
          value={email}
          error={Boolean(!email || invalidEmailError)}
          helperText={invalidEmailError}
          fullWidth
        />

        <TextField
          className={classes.inputField}
          label="Company"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BusinessIcon />
              </InputAdornment>
            ),
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField,
            },
          }}
          onChange={handleCompanyChange}
          value={company}
          fullWidth
        />

        <TextField
          margin="normal"
          label="Password"
          className={classes.password}
          onChange={handlePasswordChange}
          value={password}
          helperText={passwordTooShortMessage}
          error={Boolean(passwordTooShortMessage)}
          required
          type="password"
          InputProps={{
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField,
            },
            name: "password",
          }}
        />

        <TextField
          margin="normal"
          label="Confirm password"
          className={classes.password}
          onChange={handleConfirmPasswordChange}
          value={confirmPassword}
          required
          type="password"
          error={Boolean(notMatchingPasswordMessage)}
          helperText={notMatchingPasswordMessage}
          InputProps={{
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField,
            },
            name: "confirmPassword",
          }}
        />

        <div className={classes.formButtons}>
          {false ? (
            <CircularProgress size={26} className={classes.loginLoader} />
          ) : (
            <Button
              disabled={disableSubmit()}
              onClick={handleSaveUser}
              variant="contained"
              color="primary"
              size="large"
            >
              Register
            </Button>
          )}
        </div>
        <Snackbar
          open={successOpen}
          autoHideDuration={6000}
          onClose={handleSuccessClose}
        >
          <Alert onClose={handleSuccessClose} severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorOpen}
          autoHideDuration={6000}
          onClose={handleErrorClose}
        >
          <Alert onClose={handleErrorClose} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </PublicView>
    )
  );
}

export default withRouter(Register);
