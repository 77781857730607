import { makeStyles } from "@material-ui/styles";

export default makeStyles(
  theme => ({
    dialogPaper: {
      height : '100%'
  },

    tablePaper: {
      height : '100%'
    },

    fab: {
      margin: "0px",
      top: "auto",
      right: "20px",
      bottom: "20px",
      left: "auto",
      position: "fixed",
    },
    fabScreenshot: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    loadingMatterport:{
      position: "absolute",
      top: "50%",
      right: "50%",
      width: '100px',
      height: '100px',

    },
    projectFileListingName:{
      minWidth: 'calc(100% - 120px)',
      wordBreak: 'break-all',
    },
    fabProgress: {
      color: theme.palette.secondary.dark,
      position: "absolute",
      margin: theme.spacing(2),
      marginLeft: "-4.2em",
    },
    names: {
      margin: theme.spacing(1),
      width: "25ch",
    },
    matterportTourLink: {
      margin: theme.spacing(1),
      width: "25ch",
    },
    address: {
      margin: theme.spacing(1),
      width: "25ch",
    },
    button: {
      margin: theme.spacing(1),
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },

    paper: {
		width: "70.5ch",
    },
	add_customer_container: {
		width: "60.5ch",
	},
	btn_wrapper: {
		padding: "8px 7%"
	},
	btn_wrapper_project: {
		padding: "5px 24px"
	},
	header_wrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
  root: {
      display: "flex",
      maxWidth: "100vw",
      minHeight: "100%",
      overflowX: "hidden",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      width: `calc(100vw - 240px)`,
    },
    contentTour:{
      [theme.breakpoints.down("sm")]:{
        paddingTop: theme.spacing(7),
      },
      [theme.breakpoints.up("sm")]:{
        paddingTop: theme.spacing(8),
      },
      flexGrow: 1,
      flex: 1,
      width: `calc(100vw - 240px)`,
      overflow: 'hidden'
    },
    controls:{
      position: 'absolute',
      top: theme.spacing(19),
      left: theme.spacing(2),
    },
    controls_items:{
      margin: '0px',
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    contentShift: {
      width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    fakeToolbar: {
      ...theme.mixins.toolbar,
    },
    addProjectTitle:{
      margin: '8px 0px',
    },
    projectGrid: {
    },
    projectObject: {
      width: "100%",
      height: "100%",
    },
    projectName: {
      margin: theme.spacing(1),
    },
    projects: {
      margin: theme.spacing(2),
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    alert: {
      margin: theme.spacing(2),
    },
    textareaLinks: {
      width: "100%",
      margin: theme.spacing(1),
    },
    cameraIcon: {
      color: "#fff",
      backgroundColor: theme.palette.secondary.main,
    },

    manageUsersHeaderWrapper: {
      display:'flex',
      justifyContent:'space-between'
    },

    closeIcon: {
      cursor:'pointer'
    },

    add_user_wrapper: {
      display:'flex'
    },

    input_name_wrapper: {
      padding: "10px",
      textAlign: "left",
      justifyContent:'space-between',
      display:'flex'
    },

    input_email_wrapper: {
      paddingLeft:'15%'
    },

    add_user_button_wrapper: {
      marginLeft:'auto'
    },

    bread_crumbs_wrapper: {
      display:'flex',
      justifyContent:'space-between',
      paddingRight: '16px',
      alignItems:'center'
    },

    registration_icon: {
      width:'24px',
      height: '24px'
    },

    registration_icon_mobile: {
      width:'18px',
      height: '18px',
    },

    reset_password_icon: {
      width:'24px',
      height: '24px',
    },

    reset_password_icon_mobile: {
      width:'20px',
      height: '20px',
    },

    search_field_wrapper: {
      display:'flex',
      justifyContent:'center'
    },

    button_icon_text: {
      marginLeft: '7px'
    }
  }),

  { index: 1 },
);
