import {useState, useEffect} from "react";

const useDownloadPWA = () => {
  const [prompt, setPrompt] = useState(null);

  const promptToInstall = () => {
    if (prompt) {
      prompt.prompt();

      return setPrompt(null);
    }
    
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  useEffect(() => {
    const ready = (e) => {
        e.preventDefault();
        setPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", ready);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);
  

  return [prompt, promptToInstall];
}

export default useDownloadPWA;
