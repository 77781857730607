import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  contentTour:{
    [theme.breakpoints.down("sm")]:{
      paddingTop: theme.spacing(7),
    },
    [theme.breakpoints.up("sm")]:{
      paddingTop: theme.spacing(8),
    },
    flexGrow: 1,
    padding: 0,
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
    overflow: 'hidden'
  },
  controls:{
    position: 'absolute',
    top: theme.spacing(21),
    right: theme.spacing(2),
  },
}),
{ index: 1 },);
