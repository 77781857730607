import React, { useState, useEffect, useContext } from "react";

import {
  Grid,
  TextField,
  Button,
  Snackbar,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import BusinessIcon from "@material-ui/icons/Business";
import useStyles from "./styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Alert } from "@material-ui/lab";
import { UserContext } from "./UserProvider";
import LockIcon from "@material-ui/icons/Lock";

import Header from "../../../../components/Header/Header";

export default function Profile(props) {
  const { promptToInstall, history } = props;
  const { user, actions } = useContext(UserContext);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [invalidEmailError, setInvalidEmailError] = useState("");
  const [notMatchingPassword, setNotMatchingPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const classes = useStyles();

  useEffect(() => {
    if (user.isAuthenticated) {
      setName(user.name);
      setEmail(user.email);
      setCompany(user.company || "");
    }
  }, [user]);

  const handleSaveChanges = () => {
    if (email && name) {
      setBackdropOpen(true);
      return actions
        .saveUser(email, name, company, handleSaveError)
        .then(() => {
          setSuccessMessage(`Successfully updated user: ${name}`);
          setSuccessOpen(true);
          setBackdropOpen(false);
        })
        .catch(err => handleSaveError(err));
    }
  };

  const disableDialogSubmit = () => {
    return !(
      currentPassword &&
      newPassword &&
      confirmPassword &&
      newPassword?.length >= 8 &&
      confirmPassword === newPassword
    );
  };

  const handleChangePassword = async () => {
    setBackdropOpen(true);
    handleDialogClose();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        currentPassword,
        newPassword,
        confirmPassword,
      }),
    };

    await fetch("/api/user/updatePassword", requestOptions)
      .then(response =>
        response.json().then(data => ({
          data: data,
          status: response.status,
        })),
      )
      .then(response => {
        setBackdropOpen(false);
        if (response.status === 200 && response.data && response.data.success) {
          setSuccessMessage("Successfully updated password");
          setSuccessOpen(true);
        } else {
          if (response.data.error) {
            setErrorMessage(response.data.error);
          } else {
            setErrorMessage("Something want wrong while updating password");
          }

          setErrorOpen(true);
        }
      })
      .catch(err => {
        setBackdropOpen(false);
        setErrorMessage("Something want wrong while updating password");
        setErrorOpen(true);
      });
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const openChangePasswordDialog = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setDialogOpen(true);
  };

  const handleSaveError = err => {
    if (err) {
      setErrorMessage(`Something want wrong while saving user`);
      setErrorOpen(true);
    }
  };

  const handleConfirmPasswordChange = event => {
    if (newPassword !== event.target.value) {
      setNotMatchingPassword("Passwords not matching");
    } else {
      setNotMatchingPassword("");
    }
    setConfirmPassword(event.target.value);
  };
  const handleEmailChange = event => {
    const emailRegex = /^(([^<>()[]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(String(event.target.value).toLowerCase())) {
      setInvalidEmailError("Invalid email");
    } else {
      setInvalidEmailError("");
    }
    setEmail(event.target.value);
  };
  const handleNameChange = event => {
    setName(event.target.value);
  };
  const handleCompanyChange = event => {
    setCompany(event.target.value);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };
  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };


  return (
    user && (
      <>
        <Header history={history} promptToInstall={promptToInstall}/>
        <div className={classes.content}>
          <div className={classes.fakeToolbar} />
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <TextField
                required
                className={classes.inputField}
                label="Name"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                value={name}
                error={Boolean(!name)}
                onChange={handleNameChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                required
                className={classes.inputField}
                label="Email"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={handleEmailChange}
                value={email}
                error={Boolean(!email || invalidEmailError)}
                helperText={invalidEmailError}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <TextField
                className={classes.inputField}
                label="Company"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={handleCompanyChange}
                value={company}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            spacing={2}
          >
            <Grid item xs={6} sm={6}>
              <Button
                color="secondary"
                variant="contained"
                className={classes.button}
                onClick={openChangePasswordDialog}
              >
                Change password
              </Button>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                style={{ float: "right" }}
                onClick={handleSaveChanges}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
          <Snackbar
            open={successOpen}
            autoHideDuration={2000}
            onClose={handleSuccessClose}
          >
            <Alert onClose={handleSuccessClose} severity="success">
              {successMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={errorOpen}
            autoHideDuration={5000}
            onClose={handleErrorClose}
          >
            <Alert onClose={handleErrorClose} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
          <Backdrop open={backdropOpen} onClick={handleBackdropClose}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog
            id="change-password-dialog"
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="form-dialog-title"
            classes={{
              paper: classes.paper,
            }}
          >
            <DialogTitle id="form-dialog-title">Change password</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                label="Current password"
                InputProps={{ name: "currentPassword" }}
                onChange={e => setCurrentPassword(e.target.value)}
                value={currentPassword}
                className={classes.password}
                required
                variant="outlined"
                type="password"
                autoComplete="current-password"
              />
              <Divider />
              <TextField
                label="New Password"
                InputProps={{ name: "password" }}
                className={classes.password}
                onChange={e => {
                  setNewPassword(e.target.value);
                }}
                value={newPassword}
                helperText="Minimum 8 characters"
                required
                variant="outlined"
                type="password"
              />
              <TextField
                label="Confirm password"
                InputProps={{ name: "confirmPassword" }}
                className={classes.password}
                onChange={handleConfirmPasswordChange}
                value={confirmPassword}
                required
                variant="outlined"
                type="password"
                error={Boolean(notMatchingPassword)}
                helperText={notMatchingPassword}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleChangePassword}
                className={classes.password}
                color="primary"
                disabled={disableDialogSubmit()}
                variant="contained"
                startIcon={<LockIcon />}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    )
  );
}
