import React, { useState, createContext, useEffect } from "react";
import { withRouter } from "react-router-dom";

export const UserContext = createContext({
  user: {},
  actions: {},
});

function UserProvider(props) {
  const [user, setUser] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchUser = async () => {
      if (!isLoading) {
        setIsLoading(true);
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
        return fetch("/api/user/getCurrentUser", requestOptions)
          .then(response =>
            response.json().then(data => ({
              data: data,
              status: response.status,
            })),
          )
          .then(response => {
            if (
              response.status === 200 &&
              response.data &&
              response.data.user
            ) {
              setUser(response.data.user, user);
              setIsLoaded(true);
              setIsLoading(false);
            } else {
              setIsLoaded(true);
            }
          })
          .catch(() => {
            setIsLoaded(true);
          });
      }
    };
    isLoaded || fetchUser();
  }, [user, isLoaded, isLoading]);

  const signOut = async history => {
    setIsLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    await fetch("/api/user/logout", requestOptions)
      .then(response =>
        response.json().then(data => ({
          data: data,
          status: response.status,
        })),
      )
      .then(response => {
        if (response.status === 200 && response.data && response.data.success) {
          setUser(null);
          history.push("/login");
          setIsLoading(false);
        } else {
        }
      })
      .catch(() => {});
  };

  const loginUser = async (
    email,
    password,
    history,
    setError,
    setIsLoadingFromLoginPage,
  ) => {
    setIsLoading(true);
    setIsLoadingFromLoginPage(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        password,
      }),
    };

    await fetch("/api/user/authenticate", requestOptions)
      .then(response =>
        response.json().then(data => ({
          data: data,
          status: response.status,
        })),
      )
      .then(response => {
        if (response.status === 200 && response.data && response.data.user) {
          setUser(response.data.user);
          if (response.data.user.isAdmin) {
            history.push("/app/admin");
          } else {
            history.push("/app/dashboard");
          }

          setIsLoading(false);
          setIsLoadingFromLoginPage(false);
        } else {
          setError(true);
          setIsLoading(false);
          setIsLoadingFromLoginPage(false);
        }
      })
      .catch(err => {
        setIsLoading(false);
        setIsLoadingFromLoginPage(false);
        setError(true);
      });
  };

  const saveUser = (email, name, company, handleSaveError) => {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        company,
        name,
      }),
    };
    return fetch("/api/user/updateCurrentUser", requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setUser(data.user);
          setIsLoading(false);
        } else {
          handleSaveError(true);
        }
      });
  };
  return (
    <UserContext.Provider
      value={{
        user: { ...user, isAuthenticated: !!user },
        actions: { setUser, signOut, loginUser, saveUser, isLoaded },
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export default withRouter(UserProvider);
