import React, {useEffect, useState} from "react";
import Header from "../../../../components/Header/Header"
import {getMatteportURLParams, initializeShowcaseWindow} from './functions';
import {matterportSDKKey} from './constants';
import {
    CircularProgress,
} from "@material-ui/core";

import useStyles from "./styles";
import useIsMobile from "../../../../hooks/useIsMobile";

export default function MatterportView(props) {
    const { promptToInstall, prompt } = props;

    const classes = useStyles();
    const [tourId, setTourId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isFound, setIsFound] = useState(false);

    useEffect(() => {    

        async function checkTourHash(){
            setIsLoading(true);
            const location = props.location;
            const publicLinkHash = location.pathname.split('/')[3];
    
            // Get Tour Id from router.
            const response = await fetch(`/api/tour/matterportShowcase/${publicLinkHash}`).then(data =>{
                return data.json();
            }).catch((error)=>{
                console.log(error);
            });
    
            if(response && response.tourLink){
                setTourId(response.tourLink);
                setIsFound(true);
            }else{
                setTourId(undefined);
            }

            setIsLoading(false);
        }

        checkTourHash();
    }, []);

    useEffect(() => {
        if(!isLoading && tourId){
            const matterportLink = `/matterportSDK/showcase.html?m=${tourId}&applicationKey=${matterportSDKKey}${getMatteportURLParams()}`;

            initializeShowcaseWindow(matterportLink);
        }
    }, [isLoading, tourId]);

    
    const showcaseMarginTop = useIsMobile() ? 55 : 64;

    return(
        <>
        {
            isLoading &&(
                <div 
                    className={classes.loadingMatterport}
                >
                    <CircularProgress size={100} />
                </div>
            )
        }
        {
            !isFound && !isLoading &&(
                <>
                    <p> Not Found </p>
                </>
            )
        }
        {/* Render Iframe*/}
        {
            !isLoading && tourId &&(
                <>
                    <Header promptToInstall={promptToInstall} prompt={prompt}/>
                    <div style={{height: '100%', display: 'flex'}}>
                        <div style={{flex: 1, width:"100vw", marginTop: showcaseMarginTop, overflow:"hidden"}}>
                            <iframe
                                title="ShowcaseTitle"
                                id="showcase"
                                frameBorder="0"
                                style={{
                                    height:"100%",
                                    width:"100%"
                                }}
                                allowFullScreen
                            />
                        </div>
                    </div>
                </>
            )
        }
        </>
    );
};
