import React from "react";
import {isSafari, browserName } from 'react-device-detect';
import {
    Icon,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import IosShareIcon from '@mui/icons-material/IosShare';

import useStyles from '../../pages/dashboard/components/admin/styles';

const AddToHomeScreenModal = (props) => {
    const {isModalOpen, setIsModalOpen } = props;
    
    const classes = useStyles();

    const onClose = () => {
        setIsModalOpen(false);
    }

    const renderDialogContent = () => {
        if(isSafari)
            return renderSafariContent();

        return renderNonSafariContent();
    }

    const renderNonSafariContent = () => {
        return(
            <React.Fragment>
                <p>{`1. Open in Safari Browser. (${browserName} does not support this)`}</p> 
                <div style={{display: 'flex', gap: 5}}>
                    <p style={{margin:0}}>2. Select the Share Button</p>
                    <Icon style={{marginTop: -7}}><IosShareIcon/></Icon>
                </div>
                <p>3. Choose Add To Home Screen</p>
                <p>4. Select Add on top right</p>
            </React.Fragment>
        );
    }

    const renderSafariContent = () => {
        return(
            <React.Fragment>
                <div style={{display: 'flex', gap: 5}}>
                    <p style={{margin:0}}>1. Select the Share Button</p>
                    <Icon style={{marginTop: -7}}><IosShareIcon/></Icon>
                </div>
                <p>2. Choose Add To Home Screen</p>
                <p>3. Select Add on top right</p>
            </React.Fragment>
        );
    }

    return(
        <Dialog
            id="add-to-home-screen"
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            aria-labelledby="form-dialog-title"
            width="xxxs"
            classes={{paper: classes.paper}}
        >
            <DialogTitle>Add To Home Screen</DialogTitle>
            <DialogContent>
                {renderDialogContent()}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    Close
                </Button> 
            </DialogActions>
        </Dialog>
    );
}

export default AddToHomeScreenModal;
