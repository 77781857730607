import { makeStyles } from "@material-ui/styles";


export default makeStyles(
    theme => ({
        fileUploader_input_contianer : {
            margin: '8px'
        },
        fileUploader_input : {
            display: 'none', 
            margin: '8px'
        },
        filesUploaded_container: {
            overflow: "hidden"
        },
        updateProjectSectionBox:{
            WebkitBoxShadow:"-2px 4px 18px -6px rgba(0,0,0,0.62)",
            boxShadow:"-2px 4px 18px -6px rgba(0,0,0,0.62)",
            border:"2px",
            borderRadius:"5px",
        },
        updateProjectSectionTitle:{
        marginTop: "8px",
        marginBottom:"8px", 
        paddingLeft:"8px", 
        paddingTop:"8px",
        },
        uploadTour_container:{
            marginBottom: '8px',
            alignItems:"center", 
            padding:"8px",
            justifyContent: "center",
        },
        uploadTour_item:{
            textAlign: "left",
        },
        uploadFilesSectionBox:{
            paddingTop: "8px",
        },
        matterportTourListItem_container: {
            alignItems: "center",
        },
        matterportTourListItem_link: {
            marginBottom: '8px', marginLeft: '8px'
        },
        matterportTourListItem_name: {
            marginBottom: '8px',
        },
        matterportTourListItem_deleteButton: {
            marginLeft: '8px',
        },
        iGuideTourListItem_container:{
            alignItems: "center",
        },
        iGuideTourListItem_name:{
            marginLeft: "8px",
        },
        iGuideTourListItem_deleteButton:{
            marginLeft: "12px",
        },

        dropzone: {
            minHeight: "56px",
            height: "56px",
            color: theme.palette.primary.main,
            overflow: "hidden",
            position: "relative",
            marginLeft: "8px",
          },
        dropzoneActive: {
            borderColor: theme.palette.primary.main,
            height: theme.spacing(1),
        },
        dropzoneReject: { 
            backgroundColor: "#DAA" 
        },
            dropzoneColor: {
            color: theme.palette.primary.main,
        },
            dropzoneBackgroundColor: {
            backgroundColor: theme.palette.primary.main,
            overflow: "hidden"
        },
        
    }),
);
  