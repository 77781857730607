import DeleteIcon  from '@mui/icons-material/Delete';
import React from "react";
import { TextField, Grid } from "@material-ui/core";
import {IconButton} from '@mui/material';
import Dropzone from "react-dropzone-uploader";
import useStyles from "./styles";

export default function UploadingIGuideListItem(props) {

    const classes = useStyles();

    const {
        tour,
        tourIndex,
    } = props;

    const deleteTour = ()=>{
      props.deleteUploadingIGuideTour(tour);
    }

    const DropzonePreview = ({ meta, fileWithMeta }) => {
      const { name, percent } = meta
      
      return (
      <div style={{ display: "flex"}}>
        <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica', width: "100%" }}>
          {name}, {Math.round(percent)}%
        </span>
    
        <button style={{position: "absolute", top: "15px", right: "5px", color: "#ffffff", backgroundColor: "#333333", outline: "none", border: "none", borderRadius: "5px"}}
         onClick={ () => removeUploadingZIPFile(fileWithMeta)}>
           &#x2715;
         </button>
      </div>
      )
    }

    const getUploadParams = async ({ file, meta }) => {
      const fileName = `${getDateString()}_${Math.round(Math.random() * 1e9)}_${meta.name}`;
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ fileName }),
      };
      
      const response = await fetch(
        "/api/user/getPresignedPost",
        requestOptions,
      ).then(response => response.json());
      
      return {
        url: response.presignedPost.url,
        fields: response.presignedPost.fields,
        meta: { fileUrl: response.presignedPost.url, generatedName: fileName },
      };
    };
    const getDateString = () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = `${date.getMonth() + 1}`.padStart(2, "0");
      const day = `${date.getDate()}`.padStart(2, "0");
      return `${year}${month}${day}`;
    };
    
    const handleFileUploadChange = ({ meta }, status, fileWithMeta) => {
      if(status === "uploading"){
        props.setCurrentlyUploadingZip(true);
      }
      if (status === "aborted" || status === "removed") {
        if(!tour.tourZipFileName){
          setIGuideZipName("");
        }
      } else if (status === "done") {
        setIGuideZipName( meta.generatedName);
      }
    };

    const removeUploadingZIPFile = (fileWithMeta)=>{
      fileWithMeta.remove();
      setIGuideZipName("");
    }

    const setIGuideTourName = (event) =>{
      props.setIGuideTourName(tourIndex, event.target.value);
    }

    const setIGuideZipName = (newName) =>{
      props.setIGuideZipName(tourIndex, newName);
      props.setCurrentlyUploadingZip(false);
    }

    return(
        <>
        <Grid 
          container 
          spacing={1}
          key={tourIndex}
          className={classes.iGuideTourListItem_container}
          >
            <Grid item xs={5}>
              <Dropzone
                getUploadParams={getUploadParams}
                addClassNames={{
                  dropzone: classes.dropzone,
                  dropzoneActive: classes.dropzoneActive,
                  dropzoneReject: classes.dropzoneReject,
                  inputLabel: classes.dropzoneColor,
                  inputLabelWithFiles: classes.dropzoneColor,
                  submitButton: classes.dropzoneBackgroundColor,
                  preview: classes.dropzoneColor,
                }}
                maxFiles={1}
                multiple={false}
                PreviewComponent={DropzonePreview}
                onChangeStatus={handleFileUploadChange}
                inputContent={(files, extra) =>
                  extra.reject ? "Zip files only" : "Drop ZIP file"
                }
                styles={{
                  inputLabel: (files, extra) => (extra.reject ? { color: "red" } : {}),
                }}
                accept=".zip"
                SubmitButtonComponent={null}
              />
            </Grid>

          <Grid item xs={5}>
            <TextField
                label="Tour Name"
                size="medium"
                defaultValue={tour.tourName}
                onChange={(e)=> setIGuideTourName(e)}
                variant="outlined"
                className={classes.iGuideTourListItem_name}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton 
                className={classes.iGuideTourListItem_deleteButton}
                aria-label="delete"
                size="medium"
                variant="outlined"
                onClick={ deleteTour }
                >
                <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
        </>
      );

}