import React from 'react';
import DownloadIcon from '@mui/icons-material/DownloadForOfflineRounded';

import useStyles from "./styles";

const DownloadPWA = (props) => {
    var classes = useStyles();
    const { promptToInstall, prompt, setIsModalOpen } = props;

    const onAddToHomeScreen = () => {
        if(prompt) return promptToInstall();

        return setIsModalOpen(true);
    }

    return (
        <React.Fragment>
            <div style={{display: 'flex', alignContent: 'center'}}>
                <DownloadIcon className={classes.profileMenuIcon} />
                <div onClick={onAddToHomeScreen}>
                    Add to Home Screen
                </div>
            </div>    
        </React.Fragment>
    );
}

export default DownloadPWA;
