import { makeStyles } from "@material-ui/styles";

export default makeStyles(({

    textField: {
        width: '40%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: 500
    },
}))