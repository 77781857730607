import { useState, useEffect, useMemo } from "react";

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    const handleIsMobile = () => {
        const mobileAndTabletWidth = 1024;
        const screenWidth = window.innerWidth;

        if(screenWidth < mobileAndTabletWidth)
            return setIsMobile(true);

        return setIsMobile(false);
    }
    
    useEffect(() => {
        window.addEventListener('resize', handleIsMobile);

        return () => {
            window.removeEventListener('resize', handleIsMobile);
        }
    }, []);

    useEffect(() => {
        handleIsMobile()
    },[]);

    return isMobile;
}

export default useIsMobile;
