import React from "react";

import Search from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from '@mui/material/TextField';

import useStyles from "./styles";

const SearchInput = ({searchHandler, value, placeholder}) => {

    const classes = useStyles()

    return (
        <React.Fragment>
            <TextField 
                id="standard-basic"
                className={classes.textField}
                type="search" 
                variant="standard" 
                onChange={e => searchHandler(e.target.value)}
                placeholder={placeholder}
                value={value || ""}
                InputProps={{
                    className: classes.input,
                    startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                }}
            />
            
        </React.Fragment>
    );
}

export default SearchInput;