import React, { useState, useContext } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AccountIcon from "@material-ui/icons/AccountCircle";
import classNames from "classnames";
import { NavLink, Link } from "react-router-dom";
import { isIOS } from "react-device-detect";

import { UserContext } from "../../pages/dashboard/components/user/UserProvider";

import DownloadPWA from "./DownloadPWA";
import AddToHomeScreenModal from "../Modals/AddToHomeScreenModal";

import useIsMobile from "../../hooks/useIsMobile";

// styles
import useStyles from "./styles";

export default function Header(props) {
  var classes = useStyles();
  const { promptToInstall, prompt } = props;

  const { user, actions } = useContext(UserContext);

  var [profileMenu, setProfileMenu] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobile = useIsMobile();

  const isOpenedFromApp = window.matchMedia('(display-mode: standalone)').matches;

  const canUserDowlnoadAnApp = isIOS || prompt;
  const shouldDisplayAddItem = !isOpenedFromApp && isMobile && canUserDowlnoadAnApp;

  return (
    <React.Fragment>
      <AddToHomeScreenModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <NavLink to="/">
            <img src="/logo.png" alt="logo" className={classes.logo} />
          </NavLink>

          <div className={classes.grow} />
          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={e => setProfileMenu(e.currentTarget)}
          >
            <AccountIcon classes={{ root: classes.headerIcon }} />
          </IconButton>
          <Menu
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <div className={classes.profileMenuUser}>
              <Typography variant="h4" weight="medium">
                {user.name}
              </Typography>
            </div>

            <MenuItem
              component={Link}
              to="/app/profile"
              onClick={() => setProfileMenu(null)}
              className={classNames(classes.profileMenuLink)}
            >
              <AccountIcon className={classes.profileMenuIcon} /> Profile
            </MenuItem>

            {shouldDisplayAddItem &&
              <MenuItem className={classNames(classes.profileMenuLink)} onClick={() => setProfileMenu(null)}>
                <DownloadPWA 
                  prompt={prompt}
                  setIsModalOpen={setIsModalOpen}
                  promptToInstall={promptToInstall}
                />
              </MenuItem>
            }
            
            <div className={classes.profileMenuUser}>
              <Typography
                className={classes.profileMenuLink}
                color="primary"
                onClick={() => actions.signOut()}
              >
                Sign Out
              </Typography>
            </div>
          </Menu>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
