import React from "react";
import LinkIcon from "@material-ui/icons/Link";
import * as config from "../../../../config";

export const togglePublicStatus = async (
  checked,
  project,
  tour,
  setSuccessMessage,
  setSuccessOpen,
  setErrorMessage,
  setErrorOpen,
  forceUpdate,
) => {
  const isPublic = checked;

  let currentIsPublicToggle = project.isPublic;
  let tourId = undefined;
  let tourType = undefined;

  if(tour){
    currentIsPublicToggle = tour.isPublic;
    tourId = tour._id;
    tourType = tour.tourLink ? "matterport": "iGuide";
  }

  if (currentIsPublicToggle !== checked) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectId: project._id ?? project.id,
        tourId: tourId,
        tourType: tourType,
        isPublic,
      }),
    };

    const result = await fetch("/api/project/togglePublicStatus", requestOptions)
    .then(response => response.json())
    .catch(err => {
      console.error(err);
      setErrorMessage(
        `Something want wrong while ${
          isPublic ? "publishing" : "removing public link for"
        } project: ${project.name}`,
      );
      setErrorOpen(true);
    });

    if (result && result.success) {
      setSuccessMessage(
        `Successfully ${
          isPublic ? "published" : "removed public link for"
        } project: ${project.name}`,
      );
      forceUpdate();
      setSuccessOpen(true);
    } else {
      setErrorMessage(
        `Something want wrong while ${
          isPublic ? "publishing" : "removing public link for"
        } project: ${project.name}`,
      );
      setErrorOpen(true);
    }
  }
};

export const generateProjectName = project => {
  let projectName = project.name;
  if(project.displayName){
    projectName = project.displayName;
  }
  if (project.isNotOwner) {
    return (
      <>
        {" "}
        {projectName} (<LinkIcon style={{ verticalAlign: "middle" }} /> Linked
        Project)
      </>
    );
  } else {
    return projectName;
  }
};

export const generateTourName = tour =>{
  return tour.tourName ?? '';
}

// More about parameters https://support.matterport.com/s/articledetail?language=en_US&ardId=kA05d000001DWztCAG
export const getMatteportURLParams = () =>{
  return `&play=1&qs=0&log=0&vr=1&dh=1&f=1&gt=1&mls=0&brand=1&title=1&help=0&search=0`;
}

export const initializeShowcaseWindow = (matterportLink) => {
  const showcase = document.getElementById('showcase');
  const showcaseWindow = showcase.contentWindow;

  showcase.addEventListener('load', async function() {
      try {
          showcaseWindow.MP_SDK.connect(showcaseWindow);
      }
      catch(e) {
          console.error(e);
          return;
      }
  });

  showcase.src = matterportLink;
}

export const getEmbeddedLinkForTour = (tour)=>{
  if(tour.tourLink){
    {/* Get link for Matterport tour*/}
    return `${config.default.FRONTEND_DOMAIN}/mptour/showcase/${tour.publicLinkHash}`;
  }

  {/* Get link for iGuide tour*/}
  return `${config.default.FRONTEND_DOMAIN}/tour/showcase/${tour.publicLinkHash}/index.html`;
}

export function getFormatedDate(dateToFormat) {
  let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  let formatedDate = new Date(dateToFormat);
  return formatedDate.toLocaleDateString("en-US", options).replace(',','');
}

export function getBoldedTourNameStyle(tour, mobile){
  let styles = {};
  if(mobile && tour.isPublic){
    styles = {...styles, ...{'fontWeight': 'bold'}}
  }

  return styles;
}
