import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import * as greetingTime from "greeting-time";
import logo from "./logo.png"
// styles
import useStyles from "./styles";

function PublicView(props) {
  var classes = useStyles();

  return (
    <>
      <Grid container className={classes.container}>
        <div className={classes.logotypeContainer}>
          <img src={logo} alt="logo" className={classes.logotypeImage} />
        </div>
        <div className={classes.formContainer}>
          <div className={classes.form}>
            <>
              <Box
                borderRadius={10}
                className={classes.logotypeContainerMobile}
              >
                <img src={logo} alt="logo" className={classes.logotypeImageMobile} />
              </Box>
              <Typography variant="h1" className={classes.greeting}>
                {greetingTime(new Date())}
              </Typography>
              {props.children}
            </>
          </div>
          <Typography color="primary" className={classes.copyright}>
            © 2020 MAXVISION 360°. All rights reserved.
          </Typography>
        </div>
      </Grid>
    </>
  );
}

export default withRouter(PublicView);
