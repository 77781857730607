import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";
import {
  Typography,
  Breadcrumbs,
  Link,
  Fab,
  Tooltip,
  Grid,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  Tab,
  TextareaAutosize,
  Button,
  DialogActions,
} from "@material-ui/core";
import * as downloadFile from "js-file-download";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useResponsive from "./useResopnsive";
import { initializeShowcaseWindow } from "./functions";

import ShareIcon from '@mui/icons-material/Share';

import { Alert, TabPanel, TabContext, TabList } from "@material-ui/lab";
import Iframe from "react-iframe";
import {getMatteportURLParams, getEmbeddedLinkForTour } from './functions';
import {matterportSDKKey} from './constants';

export default function ViewTour(props) {
  const classes = useStyles();

  const [project, setProject] = useState();
  const [tour, setTour] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [embadDialogOpen, setEmbadDialogOpen] = useState(false);
  const [tabValue, setTabValue] = useState("0");
  const [copied, setCopied] = useState(false);

  const publicLinkRef = useRef(null);
  const iframeRef = useRef(null);

  const responsive = useResponsive();

  const {
    projectId,
    userId,
    tourId,
    handleAllUserClick,
    handleUserClick,
    handleProjectClick,
    isFromAdmin,
    userName,
    isAgent,
    update,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ projectId, userId }),
      };

      let result = await fetch(
        "/api/project/get",
        requestOptions,
      ).then(response => response.json());
      if (result && result.project) {
        setProject(result.project);
      }

      requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tourId: tourId , projectId }),
      };
      result = await fetch(
        "/api/project/getTour",
        requestOptions,
      ).then(response => response.json());
      if (result && result.tour) {
        setTour(result.tour);
      }

    };
    fetchData();
	return () => {
		setProject(null);
	}
  }, [projectId, userId, update]);

  const viewAllUsers = event => {
    event.preventDefault();
    handleAllUserClick();
  };
  const viewProject = event =>{
    event.preventDefault();
    setProject(null);
    setTour(undefined);
    handleProjectClick(projectId, userName, userId, isAgent);
  }

  const getScreenshot = async () => {
    const angular = document.querySelector("iframe")?.contentWindow?.angular;
    if (angular) {
      const ngScope = document
        .querySelector("iframe")
        ?.contentDocument.querySelector(
          ".iguide-viewer.iguide-viewer-bootstrap.ng-scope",
        );
      const scope = angular.element(ngScope)?.scope();
      if (scope) {
        const activePano = scope.vf.viewer.floorplan.nActivePano;
        const rotation = scope.vf.viewer.angle;
        const elevation = scope.vf.viewer.elevation;

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            activePano,
            rotation,
            elevation,
            folderName: tour.tourFolderName,
          }),
        };
        setIsLoading(true);
        const result = await fetch(
          "/api/static/screenshot",
          requestOptions,
        ).then(response => response.blob());
        if (result) {
          downloadFile(
            result,
            `maxvision_screenshot_${new Date().getTime()}.png`,
            "image/png",
          );

          setIsLoading(false);
        }
      }
    }else{

    }

  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const handleChangeTabValue = (event, newTabValue) => {
    setCopied(false);
    setTabValue(newTabValue);
  };

  const openEmbedDialog = () => {
    setCopied(false);
    setTabValue("0");
    setEmbadDialogOpen(true);
  };

  const handleEmbedDialogClose = () => {
    setEmbadDialogOpen(false);
  };

  const handleGoBack = (e) =>{
    viewProject(e);
  }
  const handleToggleForceUpdate = () =>{
    tour.isPublic = !tour.isPublic;
    props.forceUpdate();
  }

  const copyLink = e => {

    if(!tour.isPublic){
      return;
    }

    let copyLinkText = '';

    if (tabValue === "0") {
      publicLinkRef.current.select();
      copyLinkText = publicLinkRef.current.value;
    } else {
      iframeRef.current.select();
      copyLinkText = iframeRef.current.value;
    }

    navigator.clipboard.writeText(copyLinkText).then(function(){
        setCopied(true);
      }, function(){
        console.error('Could not copy link!');
      });
  };

  function getTourProjectPath(tour){
    let projectPath = '';
    if(tour.tourLink){
      projectPath = `/matterportSDK/showcase.html?m=${tour.tourLink}&applicationKey=${matterportSDKKey}${getMatteportURLParams()}`;
    }else{
      projectPath = `/files/${tour.tourFolderName}/index.html`;
    }
    return projectPath;
  }


  const generateIframe = ()=>{

    
    return(
      <Iframe
        id="showcase"
        className={classes.projectObject}
        display="initial"
        position="relative"
        loading="loading"
        frameBorder={0}
        allowFullScreen={true}
      />
    );
  }

  useEffect(() => {
    if(tour){
      let projectViewSrc = getTourProjectPath(tour);

      initializeShowcaseWindow(projectViewSrc);
    }
  }, [tour]);

  return (
    <>
      {tour && (
        <>
          <Grid
            container
            direction={responsive({
              xs: "row",
              sm: "row",
              md: "column",
              lg: "column",
              xl: "column",
            })}
            style={{ width: "100%", height: "100%", display: 'flex'}}
            className={classes.projectGrid}
          >

            <Grid item style={{width: "100%", flex: 1}}>
              {generateIframe()}
            </Grid>

              <div
                className={classes.controls}
              >

                <Grid
                  style={{marginBottom:'8px'}}
		              item>
                  <Tooltip title="Go back" aria-label="go-back-to-project">
                    <Fab
                      color="secondary"
                      aria-label="add"
                      onClick={(e) => handleGoBack(e)}
                      className={`${classes.fabScreenshot} ${classes.controls_items}`}
                    >
                      <ArrowBackIcon style={{margin: '0px'}} />
                    </Fab>
                  </Tooltip>
                </Grid>

                {
                  tour.isPublic && (isFromAdmin || !project.isNotOwner) && (
                    <Grid item>
                      <Tooltip title="Embed Project" aria-label="embedProject">
                        <Fab
                          color="secondary"
                          aria-label="add"
                          onClick={openEmbedDialog}
                          className={`${classes.fabScreenshot} ${classes.controls_items}`}
                        >
                          <ShareIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  )
                }
              </div>

          </Grid>
        </>
      )}
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Dialog
        id="embed-dialog"
        open={embadDialogOpen}
        onClose={handleEmbedDialogClose}
        aria-labelledby="form-dialog-title"
        width="xs"
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle>Embed Project</DialogTitle>

        <DialogContent>
          {isAgent ? (
            <TabContext value={tabValue}>
              <TabList
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTabValue}
                aria-label="embed project"
              >
                <Tab label="Link" value="0" />
                <Tab label="Embed Html" value="1" />
              </TabList>

              <TabPanel value="0">
                {project && project.publicLink && (
                  <TextareaAutosize
                    ref={publicLinkRef}
                    value={getEmbeddedLinkForTour(tour)}
                    className={classes.textareaLinks}
                  />
                )}
              </TabPanel>

              <TabPanel value="1">
                {project && project.publicLink && (
                  <TextareaAutosize
                    ref={iframeRef}
                    value={`<div style="display:block;position:relative;padding:0 0 57% 0;overflow:hidden;height:100%;width: 95%;margin: auto;"><iframe src="${getEmbeddedLinkForTour(tour)}" style="display:block;position:absolute;top:0;left:0;width:100%;height:100%;border:0;" scrolling="no" frameborder="0" allowfullscreen></iframe></div>`}
                    className={classes.textareaLinks}
                  />
                )}
              </TabPanel>
            </TabContext>
          ) : (
            tour &&
            tour.isPublic && (
              <TextareaAutosize
                ref={publicLinkRef}
                value={getEmbeddedLinkForTour(tour)}
                className={classes.textareaLinks}
              />
            )
          )}
        </DialogContent>
        <Snackbar
          onClose={()=> setCopied(false)}
          autoHideDuration={1000}
          open={copied}
          message="Copied"
        />

        {document.queryCommandSupported("copy") && (
          <DialogActions>
            <Button color="secondary" variant="contained" onClick={copyLink}>
              Copy to clipboard
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
