import React, { useState, useEffect, Fragment } from "react";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Divider,
  List,
  Button,
  Snackbar,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Menu,
  MenuItem,
  TextareaAutosize,
} from "@material-ui/core";

import useStyles from "./styles";
import PersonIcon from "@material-ui/icons/Person";
import DeleteIcon from "@material-ui/icons/Delete";
import HomeIcon from "@material-ui/icons/Home";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { Alert } from "@material-ui/lab";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import SearchInput from "../../../../components/Search/SearchInput";

import { useDebounce } from "../../../../hooks";

import * as config from "../../../../config";

import resetPasswordIcon from "../../../../images/icons/changePasswordIcon.png";
import completeRegistrationIcon from "../../../../images/icons/registrationIcon.png";


export default function ViewAllUsers(props) {

  const [searchValue, setSearchValue] = useState("")
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [deleteUserName, setDeleteUserName] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [copyResetPassworLinkdDialogOpen, setCopyResetPasswordLinkDialogOpen] = useState(false)
  const [copyRegistrationLinkDialogOpen, setCopyRegistrationLinkDialogOpen] = useState(false);
  const [textAreaUrl, setTextAreaUrl] = useState("");
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const dialogTitle = copyRegistrationLinkDialogOpen ? <p>Complete Registration Link</p> : <p>Reset Password Link</p>
  const classes = useStyles();

  const searchWithDebounce = useDebounce(searchValue, 500);
    const fetchData = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await await fetch(
      "/api/user/getAllUsers",
      requestOptions,
    ).then(response => response.json());
    if (response && response.users) {
      setUsers(response.users)
      setIsLoading(false);
    }
  };

  const searchUsersAndProjects = async (data) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const result = await fetch(
      `/api/user/searchUsers/${data}`,
      requestOptions,
    ).then(response => response.json());

    if (result?.success) {
      return result.users;
    }  else {
      setErrorMessage(
        `Something went wrong while searching: ${data}`,
      );
      setErrorOpen(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.update]);

  useEffect(() => {
    let mounted = true;
    if (searchWithDebounce) {
      searchUsersAndProjects(searchWithDebounce)
        .then(response => {
          if (mounted && response) {
              setUsers(response)
          }
        })

      return function cleanup() {
        mounted = false
      }
    } else {
      fetchData();
    }
  },[searchWithDebounce])

  const showConfirmDialog = (userId, userName) => {
    setDeleteUserId(userId);
    setDeleteUserName(userName);
    setConfirmDialogOpen(true);
  };

  const handleDeleteUser = async () => {
    if (deleteUserId) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: deleteUserId,
        }),
      };

      const result = await fetch("/api/user/deleteUser", requestOptions)
        .then(response => response.json())
        .catch(err => {
          setErrorMessage(
            `Something want wrong while deleting user: ${deleteUserName}`,
          );
          setErrorOpen(true);
        });
      if (result && result.success) {
        setSuccessMessage(`Successfully delete user: ${deleteUserName}`);
        props.forceUpdate();
        setSuccessOpen(true);
      } else {
        setErrorMessage(
          `Something went wrong while deleting user: ${deleteUserName}`,
        );
        setErrorOpen(true);
      }
    }
  };

  const handleGenereteResetHash = async (email) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email
      }),
    };

    return await fetch("/api/user/generateResetPasswordHash", requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setSuccessMessage("Reset password link is successfully generated")
          setSuccessOpen(true);
          return data.resetPasswordHash;
        } else {
          setErrorMessage("Something went wrong while trying to reset password");
          setErrorOpen(true);
        }
      });
  }

  const handleUserClick = user => {
    props.handleUserClick(user.id);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const handleChange = (value) => {
    setSearchValue(value)

    if (!value) setSearchValue(null)
  }

  const toggleAgentStatus = async (event, user) => {
    user.isAgent = !user.isAgent;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
        isAgent: user.isAgent,
      }),
    };

    const result = await fetch("/api/user/toggleAgentStatus", requestOptions)
      .then(response => response.json())
      .catch(err => {
        setErrorMessage(
          `Something want wrong while changing status of ${user.name}`,
        );
        setErrorOpen(true);
      });
    if (result && result.success) {
      setSuccessMessage(`Successfully changed agent status of ${user.name}`);
      props.forceUpdate();
      setSuccessOpen(true);
    } else {
      setErrorMessage(
        `Something want wrong while changing status of ${user.name}`,
      );
      setErrorOpen(true);
    }
  };

  const handleCloseEmbedDialog = () => {
    setTextAreaUrl("");
    setCopyRegistrationLinkDialogOpen(false);
    setCopyResetPasswordLinkDialogOpen(false);
  }

  const generateUserString = (user) => {
    if(user.firstName?.length || user.lastName?.length){
      let userFirstAndLastName = "";

      if(user.lastName && user.firstName) userFirstAndLastName = `${user.lastName}, `;
      else if(user.lastName) userFirstAndLastName = `${user.lastName} `;

      if(user.firstName) userFirstAndLastName = userFirstAndLastName.concat(user.firstName);

      return userFirstAndLastName;
    }

    if (user.name)
      return user.name;
  }

  const handleResetPasswordOpenModal = async (email) => {
    const resetPasswordHash = await handleGenereteResetHash(email);
    const resetPasswordUrl = `${config.default.FRONTEND_DOMAIN}/login/${resetPasswordHash}`;

    setTextAreaUrl(resetPasswordUrl);
    setCopyResetPasswordLinkDialogOpen(true);
  }

  const handleRegistrationLinkOpenModal = (inviteHash) => {
    const registrationUrl = `${config.default.FRONTEND_DOMAIN}/register/${inviteHash}`;

    setTextAreaUrl(registrationUrl);
    setCopyRegistrationLinkDialogOpen(true);
  }

  const copyLink = () => {
    if(textAreaUrl){
      navigator.clipboard.writeText(textAreaUrl)
        .then(function() {
          setIsLinkCopied(true);
          handleCloseEmbedDialog();
        }, function() {
          console.error('Could not copy link!')
        });
    }

  }

  const renderCopyLinkModal = (user) => {
    return (
      <Dialog
        id="copy-link-dialog"
        open={copyRegistrationLinkDialogOpen || copyResetPassworLinkdDialogOpen}
        onClose={handleCloseEmbedDialog}
        aria-labelledby="form-dialog-title"
        width="xs"
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <TextareaAutosize
            value={textAreaUrl}
            className={classes.textareaLinks}
          />
        </DialogContent>
        {document.queryCommandSupported("copy") && (
          <DialogActions>
            <Button color="secondary" variant="contained" onClick={() => copyLink(user?.inviteHash, user?.email )}>
              Copy to clipboard
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }

  const renderUser = (user, index) => {
    return (
      <Fragment key={index}>
        {index > 0 && <Divider />}
        <ListItem
          button
          onClick={e => {
            e.preventDefault();
            handleUserClick(user);
          }}
        >
          <ListItemAvatar>
            <PersonIcon fontSize="large" />
          </ListItemAvatar>
          <ListItemText primary={generateUserString(user)} secondary={user.company} />
          <ListItemSecondaryAction>
            {getSecondaryActions(user, index)}
          </ListItemSecondaryAction>
        </ListItem>
      </Fragment>
    );
  };
  const handleMenuClick = (index, event) => {
    setMenuAnchorEl({ [index]: event.currentTarget });
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const getSecondaryActions = (user, index) => {
      return (
        <>
          <IconButton onClick={e => handleMenuClick(index, e)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl && menuAnchorEl[index]}
            open={Boolean(menuAnchorEl && menuAnchorEl[index])}
            onClose={handleMenuClose}
            getContentAnchorEl={null}
          >
            <MenuItem
              onClick={e => {
                handleMenuClose();
                toggleAgentStatus(e, user);
              }}
            >
              <Button
                startIcon={user.isAgent ? <HomeIcon /> : <HomeOutlinedIcon />}
                disableElevation
              >
                {user.isAgent ? "Mark Not Agent" : "Mark Agent"}
              </Button>
            </MenuItem>
            {!user.hasAnAccount &&
              <MenuItem onClick={() => handleRegistrationLinkOpenModal(user.inviteHash)}>
                <Button disableElevation >
                  <img src={completeRegistrationIcon} className={classes.registration_icon_mobile}/>
                  <p className={classes.button_icon_text}>
                    complete registration
                  </p>
                </Button>
              </MenuItem>
            }
            {user.hasAnAccount &&
              <MenuItem onClick={() => handleResetPasswordOpenModal(user.email)}>
                <Button disableElevation>
                <img src={resetPasswordIcon} className={classes.reset_password_icon_mobile}/>
                <p className={classes.button_icon_text}>
                  reset password
                </p>
                </Button>
              </MenuItem>
            }
            <MenuItem
              onClick={() => {
                handleMenuClose();
                showConfirmDialog(user.id, user.name);
              }}
            >
              <Button startIcon={<DeleteIcon />} disableElevation>
                Delete
              </Button>
            </MenuItem>
          </Menu>
        </>
      );
  }

  return (
    <>
      <div className={classes.search_field_wrapper} >
        <SearchInput
          placeholder="Search"
          searchHandler={handleChange}
          value={searchValue}
        />
      </div>
      <List className={classes.projects} component="nav">
        {!isLoading && users && users.length ? users.map(renderUser) : null}
      </List>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">Are you sure?</DialogTitle>
        <DialogContent>Do you want to delete {deleteUserName}?</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setConfirmDialogOpen(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setConfirmDialogOpen(false);
              handleDeleteUser();
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {renderCopyLinkModal()}
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
          onClose={()=> setIsLinkCopied(false)}
          autoHideDuration={1000}
          open={isLinkCopied}
          message="Copied"
        />
    </>
  );
}
