import React, {useContext} from "react";

import {
List,
ListItem,
ListItemIcon,
Checkbox,
ListItemText,
Button
} from "@material-ui/core"; 
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from "@material-ui/icons/Delete";

import { UserContext } from "../../user/UserProvider";
import {DOMAIN} from './ProjectFileListing.constants';

import useStyles from "./styles";

export default function ProjectFileListing({
    uploadedFiles,
    checked,
    handleFileCheckedToggle,
    handleDeleteFile
}){

    const classes = useStyles();
    const { user } = useContext(UserContext);

    const handleFileClick = (event, file) =>{
        event.preventDefault();
        window.open(`http://${DOMAIN}/files/nonzipFiles/${file}`);
    }

    const handleDownloadClick = (fileName) =>{
        let filePath = `http://${DOMAIN}/files/nonzipFiles/${fileName}`;
        let link = document.createElement('A');
        link.href = filePath;
        link.download = filePath.substring(filePath.lastIndexOf('/')+1);
        document.body.appendChild(link);
        link.click();
        link.target ="blank";
        document.body.removeChild(link);
    }

    function getStrippedFileName(fileName){
        return fileName.substring(19);
    }

    return(
        uploadedFiles ? (
            <>
            <hr/>
            {(
                <List> {
                    uploadedFiles.map((file, index) => {
                        const labelId = `checkbox-list-label-${file}`;
                        return (
                            <div 
                                key={`file-${index}`}
                                className={classes.projectFileListing_container}
                            >
                                <ListItem
                                    key={index}
                                    dense button
                                    className={classes.tour_parent}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={checked.indexOf(index) !== -1}
                                            onClick={handleFileCheckedToggle(index)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{'aria-labelledby': labelId}}
                                        />
                                    </ListItemIcon>
                                    <DescriptionIcon 
                                        className={classes.projectFileListing_icon}
                                    />
                                    <ListItemText 
                                        className={classes.projectFileListingName}
                                        id={labelId} 
                                        primary={getStrippedFileName(file)}
                                        onClick={e=>{handleFileClick(e, file)}}
                                    />
                                </ListItem>
                                
                                <Button onClick={(event) => {handleDownloadClick(file)}}>
                                    <GetAppIcon/>
                                </Button>

                                {user.isAdmin &&
                                    <Button onClick={() => handleDeleteFile(file)}>
                                        <DeleteIcon/>
                                    </Button>
                                }
                            </div>
                        );
                    })
                    }
                </List>
            )}
            <hr/>
            </>
        ):
        (<></>)
    );
}