import React, { useState, useContext, useReducer } from "react";
import ViewUser from "./components/admin/ViewUser";
import { UserContext } from "./components/user/UserProvider";
import ViewTour from "./components/admin/ViewTour";
import ViewProject from "./components/admin/ViewProject";

import Header from "../../components/Header/Header";
import useStyles from "../../components/Layout/styles";


export default function Dashboard(props) {
  const { user } = useContext(UserContext);
  const { promptToInstall, history } = props;

  const headerClasses = useStyles();
  const [component, setComponent] = useState("user");
  const [projectId, setProjectId] = useState("");
  const [update, forceUpdate] = useReducer(x => x + 1, 0);
  {/* Current tour that is being rendered (Viewing actual tour)*/}
  const [tour, setTour] = useState();

  const handleUserClick = () => {
    setComponent("user");
    setProjectId(null);
  };

  const handleProjectClick = projectId => {
    setComponent("project");
    setProjectId(projectId);
  };

  const handleAllUserClick = () => {
    setComponent("allusers");
    setProjectId(null);
  };

  const handleTourClick = (tour) =>{
    setTour(tour)
    setComponent("tour");
  }

  const renderComponent = ()=>{

    return(
      <>
        <Header history={history} promptToInstall={promptToInstall}/>
        <div className={component === "tour" ? headerClasses.contentTour : headerClasses.content}>
          {
            component !== "tour" &&(
              <div className={headerClasses.fakeToolbar} />
            )
          }
          {
            component === "project" && (
              <ViewProject
                projectId={projectId}
                handleUserClick={handleUserClick}
                forceUpdate={forceUpdate}
                
                handleMatterportTourClick={handleTourClick}
                handleIGuideTourClick={handleTourClick}
                update={update}
                isAgent={user.isAgent}
                isFromAdmin={false}
              />
            )
          }
          {
            component === "tour" && (
              <ViewTour
                userId={user.id}
                projectId={projectId}
                tourId={tour?._id}

                handleAllUserClick={handleAllUserClick}
                handleUserClick={handleUserClick}
                handleProjectClick={handleProjectClick}

                update={update}
                forceUpdate={forceUpdate}
                isFromAdmin={false}
              />
            )
          }
          {
            component !== "tour" && component !== "project" &&(
              <ViewUser
                userId={user.id}
                handleProjectClick={handleProjectClick}
                handleUserClick={handleUserClick}
                isFromAdmin={false}
                forceUpdate={forceUpdate}
                update={update}
              />
            )
          }
        </div>
      </>
    );
  }

   return (
      <>
        {
          user? (
            renderComponent()
          ):
          (<></>)
        }
      </>
   )
}
