import React, { useState, useEffect, useRef, useMemo } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Grid, Typography, Divider } from "@material-ui/core";

import useStyles from "./styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import * as config from "../../../../config";

import UpdateProject from "./AddTours/UpdateProject";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const geocoder = { current: null };

export default function AddProject(props) {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [projectDisplayName, setDisplayName] = useState("");

  const loaded = useRef(false);
  const classes = useStyles();

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.default.GOOGLE_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps",
      );
    }

    loaded.current = true;
  }

  const fetchGoogleData = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      geocoder.current = new window.google.maps.Geocoder();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchGoogleData({ input: inputValue, types: ["address"] }, results => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, fetchGoogleData]);

  const renderOption = option => {
    const matches = option.structured_formatting.main_text_matched_substrings;
    const parts = parse(
      option.structured_formatting.main_text,
      matches.map(match => [match.offset, match.offset + match.length]),
    );

    return (
      <Grid container alignItems="center">
        <Grid item>
          <LocationOnIcon className={classes.icon} />
        </Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}

          <Typography variant="body2" color="textSecondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    );
  };

	function getProjectNameFromAddress(address){
		let result = "";
		if (address.house) {
			result += `${address.house} `;
		}
		if (address.street) {
			result += address.street;
		}

		if (address.unit) {
			result += ` ${address.unit}`;
		}

		result += `, ${address.city}, ${address.state}, ${address.zip}`;
		return result;
	}

  const getPlaceDetails = option => {
    if (window.google && geocoder.current && option && option.place_id) {
      geocoder.current.geocode({ placeId: option.place_id }, result => {

        const address = result[0];
        const addressComponents = address.address_components;

        const getAddressComponent = (
          fieldName,
          useShortName,
          secondFieldName,
        ) => {
          let matchingComponent = addressComponents.find(ac =>
            ac.types.includes(fieldName),
          );

          if (!matchingComponent) {
            matchingComponent = addressComponents.find(ac =>
              ac.types.includes(secondFieldName),
            );
          }
          if (matchingComponent) {
            return useShortName
              ? matchingComponent.short_name
              : matchingComponent.long_name;
          }
          return null;
        };
        const house = getAddressComponent("street_number");
        const street = getAddressComponent("route");
        const city = getAddressComponent(
          "locality",
          false,
          "sublocality_level_1",
        );
        const state = getAddressComponent("administrative_area_level_1", true);
        const zip = getAddressComponent("postal_code");
        const latitude = address.geometry?.location?.lat();
        const longitude = address.geometry?.location?.lng();
        console.log(
          `${house} ${street}, ${city}, ${state}, ${zip}\n${latitude},${longitude}`,
        );
        let addressProp = {
          house,
          street,
          city,
          state,
          zip,
          latitude,
          longitude,
        };
        props.selectAddress(addressProp);

        if(!value && !projectDisplayName){
          let projectName = getProjectNameFromAddress(addressProp);
          setDisplayProjectName(projectName);
        }

      });
    }
  };

  const setDisplayProjectName = (projectName) =>{
    setDisplayName(projectName);
    props.setDisplayProjectName(projectName);
  }

  const handleMatterportTour = () => {

    let tour = {
      tourName: "",
      tourLink: ""
    }

    props.setMatterportTour(tour);
  }

  const handleIGuideTour = () => {

    let tour = {
      tourName: "",
      tourZipFileName: "",
      tourFolderName: ""
    }

    props.setIGuideTour(tour);
  }

  const setMatterportTourName = (tourIndex, newName) =>{
    props.setMatterportTourName(tourIndex, newName);
  }
  const setMatterportTourID = (tourIndex, newID) =>{
    props.setMatterportTourID(tourIndex, newID);
  }

  const setIGuideZipName = (tourIndex, newName) =>{
    props.setIGuideZipName(tourIndex, newName);
  }
  const setIGuideTourName = (tourIndex, newName) =>{
    props.setIGuideTourName(tourIndex, newName);
  }

  const deleteIGuideTour = (tour) =>{
    props.deleteUploadingIGuideTour(tour);
  }

  const setCurrentlyUploadingZip = (isUploading)=>{
    props.setCurrentlyUploadingZip(isUploading);
  }

  return (
    <>
      <h4> Basic Information </h4>
      <Divider/>

      <Autocomplete
        fullWidth
        id="google-map-demo"
        className={classes.addProjectTitle}
        getOptionLabel={option =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={x => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          getPlaceDetails(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          if(value && !projectDisplayName){
            setDisplayProjectName("");
          }
          setInputValue(newInputValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Add a location"
            variant="outlined"
            fullWidth
          />
        )}
        renderOption={option => renderOption(option)}
      />

      <TextField
        label="Floor, Apartment, Suit, etc"
        onChange={e => props.setUnit(e.target.value)}
        className={classes.addProjectTitle}
        variant="outlined"
        fullWidth
      />

      <TextField
        value={projectDisplayName}
        fullWidth
        label="Display Name"
        variant="outlined"
       className={classes.addProjectTitle}
        onChange={e=>setDisplayProjectName(e.target.value)}
      />

      <Divider/>
     
      <UpdateProject
        saveLoading={props.saveLoading}
        matterportTours={props.matterportTours}
        iGuideTours={props.iGuideTours}
        deleteUploadingIGuideTour={deleteIGuideTour}
        setCurrentlyUploadingZip={setCurrentlyUploadingZip}
        deleteUploadingMatterportTour={props.deleteUploadingMatterportTour}
        setMatterportTour={handleMatterportTour}
        setIGuideTour={handleIGuideTour}
        setMatterportTourName={setMatterportTourName}
        setMatterportTourID={setMatterportTourID}
        setIGuideZipName={setIGuideZipName}
        setIGuideTourName={setIGuideTourName}
        setNonzipFiles={props.setNonzipFiles}
      />

    </>
  );
}
