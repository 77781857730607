import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// components

// pages
import Dashboard from "../../pages/dashboard/Dashboard";

import Profile from "../../pages/dashboard/components/user/Profile";

function Layout(props) {
  var classes = useStyles();
  const { promptToInstall, prompt } = props;

  return (
    <div className={classes.root}>
        <Switch>
          <Route path="/app/dashboard" render={(props) => <Dashboard {...props} promptToInstall={promptToInstall} prompt={prompt}/>} />
          <Route path="/app/profile" render={(props) => <Profile {...props} promptToInstall={promptToInstall}/>} prompt={prompt}/>
        </Switch>
    </div>
  );
}

export default withRouter(Layout);
