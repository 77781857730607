import React, { useState, useContext, useEffect } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  InputAdornment,
  DialogContentText,
  Snackbar,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

import EmailIcon from "@material-ui/icons/Email";
import SaveIcon from "@material-ui/icons/Save";
import LockIcon from "@material-ui/icons/Lock";

// styles
import useStyles from "./styles";

import { UserContext } from "../dashboard/components/user/UserProvider";
import PublicView from "./PublicView";
import { Alert } from "@material-ui/lab";

function Login(props) {
  const classes = useStyles();
  const { actions } = useContext(UserContext);

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  //TO DO set this state to true
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [invalidHashDialogOpen, setInvalidHashDialogOpen] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [invalidEmailError, setInvalidEmailError] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [notMatchingPassword, setNotMatchingPassword] = useState("");

  const handleEmailChange = event => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(event.target.value?.toLowerCase())) {
      setInvalidEmailError("Invalid email");
    } else {
      setInvalidEmailError("");
    }
    setEmailAddress(event.target.value);
  };

  const disableDialogSubmit = () => {
    return !!(!emailAddress || invalidEmailError);
  };

  const handleForgotPassword = () => {
    setDialogOpen(true);
  };

  const resetPasswordHashValidity = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const result = await fetch(`/api/user/validity?resetPasswordHash=${props.match.params.resetHash}`, requestOptions)
    .then(response => response.json());
    if(result.valid) {
        return setResetPasswordDialogOpen(true)
    } else {
      return setInvalidHashDialogOpen(true)
    }
  }

  const handleChangePassword = async () => {
    const resetPasswordHash = props.match.params.resetHash;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        resetPasswordHash,
        newPassword,
        confirmPassword
      }),
    };
    await fetch("/api/user/forgotPassword",requestOptions)
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setSuccessMessage(
          "Password changed",
        );
        setSuccessOpen(true);
        setResetPasswordDialogOpen(false);
      } else {
        setErrorMessage(
          `Something want wrong while trying to change password`,
        );
        setErrorOpen(true);
      }
    })
  };

  const handleForgotPasswordSubmit = async () => {
    //call api to server,
    setSaveLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailAddress,
      }),
    };

    if (emailAddress) {
      await fetch("/api/user/resetPassword", requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setSaveLoading(false);
            setSuccessMessage(
              "If you have an account with us, you will recieve a password reset email",
            );
            setSuccessOpen(true);
            handleDialogClose();
          } else {
            setSaveLoading(false);
            setErrorMessage(
              `Something want wrong while trying to reset password`,
            );
            setErrorOpen(true);
            handleDialogClose();
          }
        })
        .catch(err => {
          setSaveLoading(false);
          setErrorMessage(
            `Something want wrong while trying to reset password`,
          );
          setErrorOpen(true);
          handleDialogClose();
        });
    }

    //setSaveLoading(false);
  };

  const handleDialogClose = () => {
    setEmailAddress("");
    setSaveLoading(false);
    setDialogOpen(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const handleConfirmPasswordChange = event => {
    if (newPassword !== event.target.value) {
      setNotMatchingPassword("Passwords not matching");
    } else {
      setNotMatchingPassword("");
    }
    setConfirmPassword(event.target.value);
  };

  const handleResetDialogClose = () => {
    setResetPasswordDialogOpen(false);
  }

  const handleInvalidDialogClose = () => {
    setInvalidHashDialogOpen(false)
  }

  const disableResetDialogSubmit = () => {
    return !(
      newPassword &&
      confirmPassword &&
      newPassword?.length >= 8 &&
      confirmPassword === newPassword
    );
  };

  useEffect(() => {
    if(props.match.params.resetHash)
      // setResetPasswordDialogOpen(true);
      resetPasswordHashValidity()
  }, [props.match.params])

  const renderInvalidResetPasswordHashModal = () => {
    return (
      <Dialog
      id="change-password-dialog"
      open={invalidHashDialogOpen}
      onClose={(_, reason) => reason === 'backdropClick' && handleInvalidDialogClose}
      aria-labelledby="form-dialog-title"
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle id="form-dialog-title">
        The token is not valid. Please request a new one.
      </DialogTitle>

      <DialogActions>
        <Button onClick={handleInvalidDialogClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
    )
  }
  const renderResetPasswordModal = () => {
    return (
      <Dialog
      id="change-password-dialog"
      open={resetPasswordDialogOpen}
      onClose={(_, reason) => reason === 'backdropClick' && handleResetDialogClose}
      aria-labelledby="form-dialog-title"
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle id="form-dialog-title">Change password</DialogTitle>
      <DialogContent>
        <TextField
          label="New Password"
          InputProps={{ name: "newPassword" }}
          className={classes.password}
          onChange={e => {
            setNewPassword(e.target.value);
          }}
          value={newPassword}
          helperText="Minimum 8 characters"
          required
          variant="outlined"
          type="password"
        />
        <TextField
          label="Confirm password"
          InputProps={{ name: "confirmPassword" }}
          className={classes.password}
          onChange={handleConfirmPasswordChange}
          value={confirmPassword}
          required
          variant="outlined"
          type="password"
          error={Boolean(notMatchingPassword)}
          helperText={notMatchingPassword}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleResetDialogClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleChangePassword}
          className={classes.password}
          color="primary"
          disabled={disableResetDialogSubmit()}
          variant="contained"
          startIcon={<LockIcon />}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
    )
  }
  return (
    <>
      <PublicView>
        <Fade in={error}>
          <Typography color="secondary" className={classes.errorMessage}>
            Something is wrong with your login or password :(
          </Typography>
        </Fade>
        <TextField
          id="email"
          InputProps={{
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField,
            },
          }}
          value={loginValue}
          onChange={e => setLoginValue(e.target.value)}
          margin="normal"
          placeholder="Email Adress"
          type="email"
          fullWidth
        />
        <TextField
          id="password"
          InputProps={{
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField,
            },
          }}
          value={passwordValue}
          onChange={e => setPasswordValue(e.target.value)}
          margin="normal"
          placeholder="Password"
          type="password"
          fullWidth
        />

        <div className={classes.formButtons}>
          {isLoading ? (
            <CircularProgress size={26} className={classes.loginLoader} />
          ) : (
            <Button
              disabled={loginValue.length === 0 || passwordValue.length === 0}
              onClick={() =>
                actions.loginUser(
                  loginValue,
                  passwordValue,
                  props.history,
                  setError,
                  setIsLoading,
                )
              }
              variant="contained"
              color="primary"
              size="large"
            >
              Login
            </Button>
          )}
          <Button
            color="primary"
            size="large"
            className={classes.forgetButton}
            onClick={handleForgotPassword}
          >
            Forget Password
          </Button>
        </div>
      </PublicView>
      {resetPasswordDialogOpen && renderResetPasswordModal()}
      {invalidHashDialogOpen && renderInvalidResetPasswordHashModal()}
      <Dialog
        id="forgot-dialog"
        open={dialogOpen}
        onClose={handleResetDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContentText>
            Please enter your email address here. We will send you a reset
            password link if you have an account with us.
          </DialogContentText>
          <TextField
            required
            className={classes.inputField}
            label="Email"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleEmailChange}
            value={emailAddress}
            error={Boolean(invalidEmailError)}
            helperText={invalidEmailError}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>

          <Button
            onClick={handleForgotPasswordSubmit}
            color="primary"
            id="submit"
            disabled={disableDialogSubmit()}
            variant="contained"
            startIcon={<SaveIcon />}
          >
            {saveLoading ? (
              <CircularProgress size={14} color="inherit" />
            ) : (
              <Typography>Submit</Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default withRouter(Login);
