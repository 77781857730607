import { makeStyles } from "@material-ui/styles";


export default makeStyles(
    theme => ({
        tour_parent:{
            
        },
        projectFileListingName: {
            wordBreak: 'break-all'
        },
        projectFileListing_container:{
            display: "flex", 
            flexDirection:"row"
        },
        projectFileListing_icon:{
            marginRight: '8px'
        },
        projectiGuideTourListing:{
            height: '35px'
        },
        projectiGuideTourListingActions:{
            marginRight: "-16px"
        },

        projectMatterportTourListing:{
            height: '35px'
        },
        projectMatterportTourListingActions:{
            marginRight: "-16px"
        }
    }),
);
  