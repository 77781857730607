import React, { useContext } from "react";
import {Route, Switch, Redirect, BrowserRouter} from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";

import Admin from "../pages/dashboard/components/admin/Admin";
import MatterportView from "../pages/dashboard/components/admin/MatterportView";

import UserProvider, {
  UserContext,
} from "../pages/dashboard/components/user/UserProvider";
import Register from "../pages/login/Register";
import useDownloadPWA from "../hooks/useDownloadPWA";

export default function App() {
  // global
  const [prompt, promptToInstall] = useDownloadPWA();

  return (
    <BrowserRouter>
      <UserProvider>
        <Switch>
          <Route exact path="/" render={() => <HomeRoute />} />
          <Route exact path="/app" render={() => <HomeRoute />} />
          <AdminRoute path="/app/admin" component={Admin} promptToInstall={promptToInstall} prompt={prompt}/>
          <PrivateRoute path="/app" component={Layout} promptToInstall={promptToInstall} prompt={prompt}/>
          <PublicRoute path="/register" component={Register} />
          <PublicRoute path="/login/:resetHash" component={(props) => <Login {...props}/>}/>
          <PublicRoute path="/login/" component={(props) => <Login {...props}/>}/>
          <Route path="/mptour/showcase" render={(props) => <MatterportView {...props} promptToInstall={promptToInstall} prompt={prompt}/>} />

          <Route path="/error" component={Error} />
          <Route component={Error} />
        </Switch>
      </UserProvider>
    </BrowserRouter>
  );

  function HomeRoute(props) {
    const { user, actions } = useContext(UserContext);
    if (!actions.isLoaded) {
      return <></>;
    } else if (user.isAdmin) {
      return (
        <Redirect
          to={{
            pathname: "/app/admin",
            state: {
              from: props.location,
            },
          }}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: "/app/dashboard",
            state: {
              from: props.location,
            },
          }}
        />
      );
    }
  }

  function PrivateRoute({ component, ...rest }) {
    const { user, actions } = useContext(UserContext);

    return (
      <Route
        {...rest}
        render={props =>
          !actions.isLoaded ? (
            <></>
          ) : user.isAuthenticated ? (
            React.createElement(component, {...props, promptToInstall: rest.promptToInstall, prompt: rest.prompt})
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function AdminRoute({ component, ...rest }) {
    const { user, actions } = useContext(UserContext);

    return (
      <Route
        {...rest}
        render={props =>
          !actions.isLoaded ? (
            <></>
          ) : user.isAdmin ? (
            React.createElement(component, {...props, promptToInstall: rest.promptToInstall, prompt: rest.prompt})
          ) : (
            <Redirect
              to={{
                pathname: "/app/dashboard",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    const { user } = useContext(UserContext);

    return (
      <Route
        {...rest}
        render={props =>
          user.isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/app",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
