import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles(
  theme => ({
    logotype: {
      color: "white",
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
      fontWeight: 500,
      fontSize: 18,
      whiteSpace: "nowrap",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    logo: {
      maxHeight: 56,
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(5),
        width: theme.spacing(20)
      },
    },
    appBar: {
      width: "100vw",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    grow: {
      flexGrow: 1,
    },
    search: {
      position: "relative",
      borderRadius: 25,
      paddingLeft: theme.spacing(2.5),
      width: 36,
      backgroundColor: fade(theme.palette.common.black, 0),
      transition: theme.transitions.create(["background-color", "width"]),
      "&:hover": {
        cursor: "pointer",
        backgroundColor: fade(theme.palette.common.black, 0.08),
      },
    },
    searchFocused: {
      backgroundColor: fade(theme.palette.common.black, 0.08),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 250,
      },
    },
    searchIcon: {
      width: 36,
      right: 0,
      height: "100%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: theme.transitions.create("right"),
      "&:hover": {
        cursor: "pointer",
      },
    },
    searchIconOpened: {
      right: theme.spacing(1.25),
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      height: 36,
      padding: 0,
      paddingRight: 36 + theme.spacing(1.25),
      width: "100%",
    },
    messageContent: {
      display: "flex",
      flexDirection: "column",
    },
    headerMenu: {
      marginTop: theme.spacing(7),
    },
    headerMenuList: {
      display: "flex",
      flexDirection: "column",
    },
    headerMenuItem: {
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
    },
    headerMenuButton: {
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.5),
    },
    headerMenuButtonCollapse: {
      marginRight: theme.spacing(2),
    },
    headerIcon: {
      fontSize: 28,
      color: "rgba(255, 255, 255, 0.35)",
    },
    headerIconCollapse: {
      color: "white",
    },
    profileMenu: {
      minWidth: 265,
    },
    profileMenuUser: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    profileMenuItem: {
      color: theme.palette.text.hint,
    },
    profileMenuIcon: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.hint,
    },
    profileMenuLink: {
      fontSize: 16,
      textDecoration: "none",
      "&:hover": {
        cursor: "pointer",
      },
    },
    messageNotification: {
      height: "auto",
      display: "flex",
      alignItems: "center",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.background.light,
      },
    },
    messageNotificationSide: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginRight: theme.spacing(2),
    },
    messageNotificationBodySide: {
      alignItems: "flex-start",
      marginRight: 0,
    },
    sendMessageButton: {
      margin: theme.spacing(4),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textTransform: "none",
    },
    sendButtonIcon: {
      marginLeft: theme.spacing(2),
    },
  }),
  { index: 1 },
);
