import React, { useState, useEffect, Fragment } from "react";
import {
  Breadcrumbs,
  Link,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  ListItem,
  List,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
  Switch,
  Container,
  Paper,
  Grid,
  Tooltip,
  TextField,
  InputAdornment,
  FormControlLabel,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
} from "@material-ui/core";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from "@material-ui/icons/Delete";
import useStyles from "./styles";
import { Alert } from "@material-ui/lab";
import BackupIcon from "@material-ui/icons/Backup";
import UndoIcon from "@material-ui/icons/Undo";
import SaveIcon from "@material-ui/icons/Save";
import LinkIcon from "@material-ui/icons/Link";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import useIsMounted from "ismounted";

import { generateProjectName } from "./functions";
import { useDebounce } from "../../../../hooks";

import ManageUsers from "./ManageUsers";
import SearchInput from "../../../../components/Search/SearchInput";

export default function ViewUser(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const classes = useStyles();
  const [user, setUser] = useState();
  const isMounted = useIsMounted();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteProjectName, setDeleteProjectName] = useState("");
  const [deleteProjectId, setDeleteProjectId] = useState("");

  const [changeProjectNameDialogOpen, setChangeProjectNameDialogOpen] = useState(false);
  const [updateProjectName, setUpdateProjectName] = useState("");
  const [updateProjectId, setUpdateProjectId] = useState("");

  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [url, setUrl] = useState("");
  const [invalidUrl, setInvalidUrl] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [usersDialogOpen, setUsersDialogOpen] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const searchWithDebounce = useDebounce(searchValue, 500);

  const { handleAllUserClick, handleProjectClick, isFromAdmin, userId } = props;

  const searchUsersAndProjects = async (data) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const result = await fetch(
      `/api/project/searchProjects/${userId}/${data}`,
      requestOptions,
    ).then(response => response.json())
    if (result?.success) {
      return result.projects;
    }  else {
      setErrorMessage(
        `Something went wrong while searching: ${data}`,
      );
      setErrorOpen(true);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (searchWithDebounce) {
      searchUsersAndProjects(searchWithDebounce)
        .then(response => {
          if (mounted && response) {
              setUser({
                ...user,
                projects: response
              });
          }
        })

      return function cleanup() {
        mounted = false
      }
    } else {
      fetchData();
    }
  },[searchWithDebounce])

  const fetchData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: userId }),
    };

    const result = await fetch(
      "/api/user/get",
      requestOptions,
    ).then(response => response.json());
    if (result && result.user && isMounted.current) {
      setUser(result.user);
      if (result.user.logoPath && !selectedFile) {
        setPreview(result.user.logoPath);
      }
      setUrl(result.user.logoUrl ?? "");
    }
  };

  useEffect(() => {
    fetchData();

    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => {
      setUser(null);
      setPreview(undefined);

      setMenuAnchorEl(null);
      setUrl(null);
      URL.revokeObjectURL(objectUrl);
    };
  }, [userId, props.update, selectedFile, isMounted]);

  const viewAllUsers = event => {
    event.preventDefault();
    handleAllUserClick();
  };

  const showConfirmDialog = (projectId, projectName) => {
    setDeleteProjectId(projectId);
    setDeleteProjectName(projectName);
    setConfirmDialogOpen(true);
  };

  const showUpdateProjectNameDialog = (project) =>{
    setUpdateProjectName(project.displayName)
    setUpdateProjectId(project._id);
    setChangeProjectNameDialogOpen(true);
  }

  const handleDeleteProject = async () => {
    if (deleteProjectId && userId) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          projectId: deleteProjectId,
        }),
      };

      const result = await fetch("/api/user/deleteProject", requestOptions)
        .then(response => response.json())
        .catch(err => {
          setErrorMessage(
            `Something want wrong while deleting project: ${deleteProjectName}`,
          );
          setErrorOpen(true);
        });
      if (result && result.success) {
        setSuccessMessage(`Successfully delete project: ${deleteProjectName}`);

        props.forceUpdate();
        setSuccessOpen(true);
      } else {
        setErrorMessage(
          `Something want wrong while deleting project: ${deleteProjectName}`,
        );
        setErrorOpen(true);
      }
    }
  };

  const handleUpdateDisplayName = async () =>{
    if(updateProjectId && userId && updateProjectName){
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          projectId: updateProjectId,
          projectName: updateProjectName
        }),
      };

      const response = await fetch("/api/user/updateProjectName", requestOptions)
      .then((response) =>{
        return response.json();
      })
      .catch(err =>{
        setErrorMessage(
          `Something went wrong while updating name: ${updateProjectName}`,
        );
        setErrorOpen(true);
      });

      if(response && response.success){
        setSuccessMessage(`Successfully updated project name!`);
        setChangeProjectNameDialogOpen(false);
        props.forceUpdate();
      }else{
        let message = response.message? response.message: '';
        setErrorMessage(`Something went wrong: ${message}`);
        setErrorOpen(true);
      }
    }
  }

  const handleInviteUser = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
      }),
    };

    const result = await fetch("/api/user/invite", requestOptions)
      .then(response => response.json())
      .catch(err => {
        setErrorMessage(`Something want wrong while inviting ${user.name}`);
        setErrorOpen(true);
      });
    if (result && result.success) {
      setSuccessMessage(`Successfully invited user: ${user.name}`);

      props.forceUpdate();
      setSuccessOpen(true);
    } else {
      setErrorMessage(`Something want wrong while inviting ${user.name}`);
      setErrorOpen(true);
    }
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const toggleStatus = async (projectId, projectName, currentStatus) => {
    if (projectId) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId: projectId,
          currentStatus: currentStatus,
        }),
      };
      const result = await fetch(
        "/api/project/toggleProjectStatus",
        requestOptions,
      )
        .then(response => response.json())
        .catch(err => {
          setErrorMessage(
            `Something want wrong while ${
              currentStatus ? "disabling" : "enabling"
            } ${projectName}`,
          );
          setErrorOpen(true);
        });
      if (result && result.success) {
        setSuccessMessage(
          `Successfully ${
            !currentStatus ? "disabled" : "enabled"
          } project: ${projectName}`,
        );

        props.forceUpdate();
        setSuccessOpen(true);
      } else {
        setErrorMessage(
          `Something want wrong while ${
            !currentStatus ? "disabling" : "enabling"
          } project: ${projectName}`,
        );
        setErrorOpen(true);
      }
    }
  };

  const handleChange = (value) => {
    setSearchValue(value)

    if (!value) setSearchValue(null)
  }


  const handleUsersDialogOpen = async () => {
    setUsersDialogOpen(true);
  };
  const handleOpenAddUserToProject = (projectId) =>{
    setProjectId(projectId);
    setUsersDialogOpen(true);
  }

  const renderProject = (project, index) => {
    return (
      <Fragment key={index}>
        {index > 0 && <Divider />}
        <ListItem
          button
          onClick={e => {
            e.preventDefault();
            handleProjectClick(project._id, user.name, user.id, user.isAgent);
          }}
          disabled={project.disabled && !props.isFromAdmin}
        >
          <ListItemText
            onClick={()=>{}}
            primary={generateProjectName(project)}
            secondary={new Date(project.uploaded).toLocaleDateString()}
          />
          {
            <ListItemSecondaryAction style={{display:'inline-flex', alignItems: 'center'}}>
              {getSecondaryActions(project, index)}
            </ListItemSecondaryAction>
          }
        </ListItem>
      </Fragment>
    );
  };

  const handleMenuClick = (index, event) => {
    setMenuAnchorEl({ [index]: event.currentTarget });
  };

  const forceUpdate = () =>{
    props.forceUpdate();
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const getSecondaryActions = (project, index) => {
    if (mobile) {
      const menuItems = [];

      if ( (isFromAdmin && !project.isNotOwner) || (!isFromAdmin && !project.isNotOwner) ) {
        menuItems.push(
          <MenuItem
            key="disable"
            onClick={() => {
              handleMenuClose();
              toggleStatus(project._id, project.name, project.disabled);
            }}
          >
            <Button
              startIcon={
                project.disabled ? <ToggleOffIcon /> : <ToggleOnIcon />
              }
              disableElevation
            >
              {project.disabled ? "Enable" : "Disable"}
            </Button>
          </MenuItem>,
        );
        menuItems.push(
          <MenuItem
            key="addUsersToProject"
            onClick={() => {
              handleOpenAddUserToProject(project._id)
            }}
          >
            <Button startIcon={<PersonAddIcon />} disableElevation>
              Add Users To Project
            </Button>
          </MenuItem>,
        );
      }

      if( (isFromAdmin && !project.isNotOwner)  ){
        menuItems.push(
          <MenuItem
            key="delete"
            onClick={() => {
              handleMenuClose();
              showConfirmDialog(project._id, project.displayName? project.displayName: project.name);
            }}
          >
            <Button startIcon={<DeleteIcon />} disableElevation>
              Delete
            </Button>
          </MenuItem>,
        );
        menuItems.push(
          <MenuItem
            key="updateProjectName"
            onClick={() => {
              showUpdateProjectNameDialog(project)
            }}
          >
            <Button startIcon={<DriveFileRenameOutlineIcon />} disableElevation>
              Update Project Name
            </Button>
          </MenuItem>,
        );
      }
      // Project is disabled and we are not owner of this project ( project.isNotOwner flag means that this project is Linked or shared with some User).
      if ( !project.disabled && !project.isNotOwner ) {
        return (
          <>
            <IconButton onClick={e => handleMenuClick(index, e)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl && menuAnchorEl[index]}
              open={Boolean(menuAnchorEl && menuAnchorEl[index])}
              onClose={handleMenuClose}
              getContentAnchorEl={null}
            >
              {menuItems}
            </Menu>
          </>
        );
      }
    } else {
      return (
        <>

          { ( (isFromAdmin && !project.isNotOwner) || (!isFromAdmin && !project.isNotOwner)   ) && (
            <>
              <IconButton
                    style={{marginRight:"16px"}}
                    onClick={()=>handleOpenAddUserToProject(project._id)}
                  >
                    <PersonAddIcon/>
              </IconButton>
              <FormControlLabel
                control={
                  <Switch
                    checked={!project.disabled}
                    onChange={() =>
                      toggleStatus(project._id, project.name, project.disabled)
                    }
                  />
                }
                label="Enabled"
                labelPlacement="end"
              />
            </>
            )
          }
          { (isFromAdmin && !project.isNotOwner)  && (
            <>
              <IconButton
                aria-label="updateProjectName"
                style={{marginRight:"16px"}}
                onClick={() =>  showUpdateProjectNameDialog(project) }
              >
                <DriveFileRenameOutlineIcon />
              </IconButton>

              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => showConfirmDialog(project._id, project.displayName? project.displayName: project.name)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      );
    }
  };

  const getInvitationStatus = () => {
    const userWasInvited = user.inviteDate
      ? ", he was invited on " + new Date(user.inviteDate).toDateString()
      : "";

    const notCreatedAccount = (
      <Typography variant="subtitle1">
        User had not created an account yet{userWasInvited}
      </Typography>
    );

    const inviteButton = (
      <Button color="primary" variant="outlined" onClick={handleInviteUser}>
        Invite
      </Button>
    );

    const dayInMiliseconds = 1000 * 60 * 60 * 24;
    const daySinceInvitation = Math.round(
      (new Date() - new Date(user.inviteDate)) / dayInMiliseconds,
    );
    const invitationExpired =
      daySinceInvitation > 7 ? (
        <>
          <Typography variant="subtitle2">
            Invitation link already expired, do you want to invite him again?
          </Typography>
          <br />
          {inviteButton}
        </>
      ) : user.inviteDate ? (
        <>
          <Typography>
            his invite link will expire in {7 - daySinceInvitation} days, do you
            want to invite him again?
          </Typography>
          <br />
          {inviteButton}
        </>
      ) : (
        inviteButton
      );

    return (
      <>
        <Alert severity="warning" variant="outlined" className={classes.alert}>
          {notCreatedAccount}
          {invitationExpired}
        </Alert>
      </>
    );
  };

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
    setHasChanges(true);
  };

  const undoLogoChanges = () => {
    setSelectedFile(undefined);
    props.forceUpdate();
  };

  const saveLogoChanges = async () => {
    if (selectedFile || hasChanges) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("userId", user.id);
      formData.append("logoUrl", url);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      const result = await fetch("/api/user/uploadLogo", requestOptions)
        .then(response => response.json())
        .catch(e => {
          setErrorMessage(`Something want wrong uploading logo`);
          setErrorOpen(true);
          setSelectedFile(undefined);
          document.getElementById("upload-button").value = "";
        });
      if (result && result.success) {
        setSuccessMessage(`Successfully updated logo`);
        setSuccessOpen(true);
        setSelectedFile(undefined);
      } else {
        setErrorMessage(`Something want wrong uploading logo`);
        setErrorOpen(true);
        setSelectedFile(undefined);
        document.getElementById("upload-button").value = "";
      }
    }
  };

  const isValidURL = url => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i",
    );
    return !!pattern.test(url);
  };

  const handleUrlChange = event => {
    const value = event.target.value;
    if (!isValidURL(value) && value) {
      setInvalidUrl("Invalid Url");
    } else {
      setInvalidUrl("");
    }

    setUrl(value);
    setHasChanges(true);
  };

  const getLogoPath = () => {
    if (user.isAgent) {
      return (
        <Container style={{ margin: 20 }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="flex-start"
            alignContent="flex-start"
          >
            <Grid item>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="upload-button"
                    type="file"
                    onChange={onSelectFile}
                  />
                  <label htmlFor="upload-button">
                    <Tooltip title="Change Logo">
                      <IconButton
                        color="secondary"
                        variant="outlined"
                        component="span"
                      >
                        <BackupIcon />
                      </IconButton>
                    </Tooltip>
                  </label>
                </Grid>

                {hasChanges && (
                  <>
                    <Grid item>
                      <Tooltip title="Reset">
                        <IconButton
                          variant="outlined"
                          onClick={undoLogoChanges}
                        >
                          <UndoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      <Tooltip title="Save Changes">
                        <IconButton
                          color="primary"
                          variant="outlined"
                          onClick={saveLogoChanges}
                        >
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            {(user.logoPath || preview) && (
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Paper elevation={3}>
                      <img
                        src={preview}
                        id="logoImage"
                        alt="logo"
                        style={{ maxWidth: "50ch" }}
                      ></img>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Url"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LinkIcon />
                          </InputAdornment>
                        ),
                      }}
                      id="logo-url"
                      onChange={handleUrlChange}
                      value={url}
                      error={Boolean(invalidUrl)}
                      helperText={invalidUrl}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      );
    }
  };

  return (
    <>
      {isFromAdmin && (
        <Breadcrumbs aria-label="breadcrumb">
          <Link href="#" color="inherit" onClick={e => viewAllUsers(e)}>
            All users
          </Link>
          <Typography color="textPrimary" aria-current="page">
            {user && user.name}
          </Typography>
        </Breadcrumbs>
      )}

      {user && (
        <Typography
          variant="h4"
          className={classes.projectName}
          style={user.managingUserName ? { color: "cadetblue" } : {}}
        >
        <div style={{display:'flex', justifyContent:'center'}}>
          <SearchInput
            placeholder="Search"
            searchHandler={handleChange}
            value={searchValue}
          />
        </div>
          {user && user.name}
          {user.managingUserName && ` (Added by ${user.managingUserName})`}
        </Typography>
      )}

      {isFromAdmin && user && !user.hasAnAccount && getInvitationStatus()}
      {user && user.isAgent && getLogoPath()}

      {user && user.projects && user.projects.length ? (
        <List className={classes.projects} component="nav">
          {
            user.projects.map(renderProject)
          }
        </List>
      ) : null}


      {usersDialogOpen &&(
        <ManageUsers
          projectId={projectId}
          isFromAdmin={isFromAdmin}
          handleUsersDialogOpen={handleUsersDialogOpen}
          usersDialogOpen={usersDialogOpen}
          setUsersDialogOpen={setUsersDialogOpen}
        />
      )}

      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">Are you sure?</DialogTitle>
        <DialogContent>
          Do you want to delete {deleteProjectName}?
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setConfirmDialogOpen(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setConfirmDialogOpen(false);
              handleDeleteProject();
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={changeProjectNameDialogOpen}
        onClose={()=> setChangeProjectNameDialogOpen(false)}
        aria-labelledby="change-projectName-dialog"
      >
        <DialogTitle id="update-projectName-dialog">Change Project Name</DialogTitle>
        <DialogContent>
            <TextField
              label="New Project Name"
              onChange={(e)=> setUpdateProjectName(e.target.value)}
              value={updateProjectName}
              variant="outlined"
            >
            </TextField>
        </DialogContent>
        <DialogActions>
        <Button
            variant="contained"
            onClick={() => setChangeProjectNameDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleUpdateDisplayName();
            }}
            color="primary"
          >
            confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
