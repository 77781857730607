import React from "react"
import { 
  Grid,
} from "@material-ui/core";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {IconButton} from '@mui/material';
import UploadingIGuideListItem from "./UploadingIGuideListItem";
import UploadingMatterportListItem from "./UploadingMatterportListItem";
import UploadingFilesComponent from "./UploadingFilesComponent";
import useStyles from "./styles";

export default function({
	saveLoading,
	matterportTours,
	iGuideTours,
	deleteUploadingIGuideTour,
	deleteUploadingMatterportTour,
	setCurrentlyUploadingZip,
	setMatterportTour,
	setIGuideTour,
	setMatterportTourName,
	setMatterportTourID,
	setIGuideZipName,
	setIGuideTourName,
	setNonzipFiles
}){

	const classes = useStyles();

	const addNewEmptyMatterportTour = () => {
	let tour = {
		tourName: "",
		tourLink: ""
	}
	setMatterportTour(tour);
	}

  const addNewEmptyIGuideTour = () => {

    let tour = {
      tourName: "",
      tourZipFileName: "",
      tourFolderName: ""
    }

    setIGuideTour(tour);
  }

  const handleMatterportTourName = (tourIndex, newName) =>{
    setMatterportTourName(tourIndex, newName);
  }
  const handleMatterportTourID = (tourIndex, newID) =>{
    setMatterportTourID(tourIndex, newID);
  }

  const handleIGuideZipName = (tourIndex, newName) =>{
    setIGuideZipName(tourIndex, newName);
  }
  const handleIGuideTourName = (tourIndex, newName) =>{
    setIGuideTourName(tourIndex, newName);
  }

  const deleteIGuideTour = (tour) =>{
    deleteUploadingIGuideTour(tour);
  }

  const handleCurrentlyUploadingZip = (isUploading)=>{
    setCurrentlyUploadingZip(isUploading);
  }

  const handleSetNonzipFiles = (files) =>{
    setNonzipFiles(files);
  }
  
  function disableIGuideAddButton(){
    const tours = [...iGuideTours];
    if(tours){

      for(let i =0; i< tours.length; i++){
        if(!tours[i].tourZipFileName){
          return true;
        }
      }

      return false;
    }
    return false;
  }

	return(
	<>
		{(
			<>
				{/* Show and Add Matterport Tour*/}
				<div className={classes.updateProjectSectionBox}>
					<h4 className={classes.updateProjectSectionTitle}> Matterport Tours </h4>
					{
						matterportTours && (matterportTours.map((tour, index) =>(
							<UploadingMatterportListItem
								tour={tour}
								tourIndex={index}
								setMatterportTourName={handleMatterportTourName}
								setMatterportTourID={handleMatterportTourID}
								deleteUploadingMatterportTour={deleteUploadingMatterportTour}
							/>))
						)
					}
					<Grid 
						container 
						direction="row"
						spacing={1}
						className={classes.uploadTour_container}
						justifyContent="center"
					>
						<Grid 
						item 
						xs={12}
						className={classes.uploadTour_item}
						>
						<IconButton
							aria-label="addMatterportTour"
							size="medium"
							variant="outlined"
							onClick={addNewEmptyMatterportTour}
						>
							<AddCircleIcon/>
						</IconButton>
						</Grid>
					</Grid>
				</div>

				{/* Show and Add IGuide Tour */}
				<div className={classes.updateProjectSectionBox}>
					<h4 className={classes.updateProjectSectionTitle}> iGuide Tours </h4>
					{
						iGuideTours && (iGuideTours.map((tour, index)=>
						<UploadingIGuideListItem 
							tour={tour}
							tourIndex={index}
							setIGuideTourName={handleIGuideTourName}
							setIGuideZipName={handleIGuideZipName}
							deleteUploadingIGuideTour={deleteIGuideTour}
							setCurrentlyUploadingZip={handleCurrentlyUploadingZip}
						/>
						))
					}
					<Grid
						direction="row"
						container
						justifyContent="center"
						spacing={1}
						className={classes.uploadTour_container}
					>
						<Grid 
							item
							xs={12}
							className={classes.uploadTour_item}
						>
							<IconButton
								aria-label="addIGuideTour"
								size="medium"
								variant="outlined"
								disabled={disableIGuideAddButton()}
								onClick={addNewEmptyIGuideTour}
							>
								<AddCircleIcon/>
							</IconButton>
						</Grid>
					</Grid>
				</div>

				{
					<UploadingFilesComponent
						disabled={saveLoading}
						setNonzipFiles={handleSetNonzipFiles}
					/>
				}
			</>
		)}
	</>)
}